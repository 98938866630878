// ** React Imports
import { Outlet, useNavigate, Navigate, Link } from 'react-router-dom';
import React from 'react';

// import { Redirect } from "react-router-dom";

// ** Router Import
import Router from './../../../../router/Router';
import VerticalLayout from '@src/layouts/VerticalLayout';

// ** Core Layout Import
// !Do not remove the Layout import
// import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import navigation from '@src/navigation/vertical';

// ** Actions
import { handleLogin } from '@store/authentication';

// ** Context
import { AbilityContext } from '@src/utility/context/Can';

// ** Utils
import { selectThemeColors } from '@utils';
import { getHomeRouteForLoggedInUser } from '@utils';
import { isUserLoggedIn } from '@utils';

// ** React Imports
import { Fragment, useEffect, useRef, useState, useContext } from 'react';

// ** Third Party Components
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

// ** Custom Hook
import useJwt from '../../../auth/jwt/useJwt';
import { useDispatch } from 'react-redux';

import { useQueryClient } from 'react-query';
import { Controller, useForm } from 'react-hook-form';

// ** Custom Hook
import AsyncSelect from 'react-select/async';
import { fetchUserGroup } from '../../../../components/react-select/fetchUserGroup';

import Cookies from 'js-cookie';

// ** Reactstrap Imports
import {
  Button,
  Badge,
  Input,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Col,
  Label,
  Row,
  CardBody,
  FormFeedback
} from 'reactstrap';
import { getUserData } from '../../../../utility/Utils';
import { DefaultRoute } from '../../../../router/routes';

const UserGroupDropdown = ({ userGroup, setUserGroup, isFetchData, setIsFetchData }) => {
  // ** State
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [userData, setUserData] = useState(null);
  const [isLoadingGetUserGroup, SetisLoadingGetUserGroup] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // ** react-hook-form
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue: setValue,
    getValues,
    resetField,
    control: control
  } = useForm();

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(getUserData());
    }
  }, []);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = DefaultRoute;
    navigate(path);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // useEffect
  useEffect(() => {
    if (dropdownOpen == false) {
      setValue('userGroup', '');
      resetField('userGroup');
      setValue('userGroupName', '');
      resetField('userGroupName');
    }
  }, [dropdownOpen]);

  const [items, setItems] = useState([]);

  const renderUserGroupItems = () => {
    return (
      <Row form>
        <Col className="col-12">
          <Controller
            id="userGroup"
            control={control}
            name="userGroup"
            rules={{ required: 'User Group is required' }}
            render={({ field }) => (
              <>
                <AsyncSelect
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={fetchUserGroup}
                  getOptionLabel={(e) => e.USER_GROUP_NAME}
                  getOptionValue={(e) => e.USER_GROUP_ID}
                  className={classnames('react-select', {
                    'is-invalid': errors.userGroup
                  })}
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  {...field}
                  placeholder="Select User Group ..."
                />
                {errors.userGroup && <FormFeedback type="invalid">{errors.userGroup.message}</FormFeedback>}
              </>
            )}
          />
        </Col>
      </Row>
    );
  };

  const onSubmit = (props) => {
    routeChange();
    setUserGroup(getValues('userGroup').USER_GROUP_NAME);

    const datItems = JSON.parse(localStorage.getItem('userData'));
    // const datItems = JSON.parse(Cookies.get("userData"));
    if (datItems) {
      datItems.userGroup = getValues('userGroup').USER_GROUP_NAME;
      datItems.userGroupId = getValues('userGroup').USER_GROUP_ID;
      localStorage.setItem('userData', JSON.stringify(datItems));
      // Cookies.set("userData", JSON.stringify(datItems), { expires: 1 });
    }
    setUserData(getUserData());
    setIsFetchData(true);
    setDropdownOpen(false);
  };

  /*eslint-enable */

  return (
    <UncontrolledDropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      tag="li"
      className="dropdown-notification nav-item me-0"
      style={{ zIndex: 999 }}
    >
      <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
        <div className="user-nav d-sm-flex">
          <span className="user-name fw-bold custom-fs">{userData && userData['username']}</span>
          <span className="user-status custom-fs">{userData && userData.userGroup}</span>
        </div>
      </DropdownToggle>

      <DropdownMenu end tag="ul" className="dropdown-menu-media" style={{ overflow: 'visible' }}>
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h6 className="user-group-title mb-0">User Group</h6>
          </DropdownItem>
        </li>
        <CardBody>{renderUserGroupItems()}</CardBody>

        <li className="dropdown-menu-footer">
          <Button color="primary" outline={isLoadingGetUserGroup} onClick={handleSubmit(onSubmit)} block>
            {isLoadingGetUserGroup ? (
              <>
                <Spinner size="sm" />
                <span className="ms-50">Loading...</span>
              </>
            ) : (
              <>Change User Group</>
            )}
          </Button>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserGroupDropdown;
