import { useQuery, useMutation } from 'react-query';
import EmployeeService from '../../../services/EmployeeService';
// - Create
const create = (dataItem) => {
  const data = EmployeeService.create(dataItem);
  return data;
};

const useCreate = (onSuccess, onError) => {
  return useMutation(create, {
    onSuccess,
    onError,
  });
};

// - Create
const createPassPro = (dataItem) => {
  const data = EmployeeService.createPassPro(dataItem);
  return data;
};

const useCreatePassPro = (onSuccess, onError) => {
  return useMutation(createPassPro, {
    onSuccess,
    onError,
  });
};

// - Search
const search = (params) => {
  return EmployeeService.search(params);
};

const useSearch = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['EMPLOYEE', params], () => search(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

// - Search
const searchUserProbation = (params) => {
  return EmployeeService.searchUserProbation(params);
};

const useSearchUserProbation = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['USER_PROBATION', params], () => searchUserProbation(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

const searchEmployeeIdByFlow = (params) => {
  return EmployeeService.searchEmployeeIdByFlow(params);
};

const useSearchEmployeeIdByFlow = (params) => {
  return useQuery(['EMPLOYEE_FLOW', params], () => searchEmployeeIdByFlow(params), {
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

// - Update
const updateUserProbation = (dataItem) => {
  const data = EmployeeService.updateUserProbation(dataItem);
  return data;
};

const useUpdateUserProbation = (onSuccess, onError) => {
  return useMutation(updateUserProbation, {
    onSuccess,
    onError,
  });
};

// - Search
const searchEmployeeByEmployeeCode = (params) => {
  return EmployeeService.searchEmployeeByEmployeeCode(params);
};

const useSearchEmployeeByEmployeeCode = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['EMPLOYEE', params], () => searchEmployeeByEmployeeCode(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

// - Search
const searchApprover = (params) => {
  return EmployeeService.searchApprover(params);
};

const useSearchApprover = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['EMPLOYEE_APPROVER', params], () => searchApprover(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    // staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Search
const searchFlexTimeApprover = (params) => {
  return EmployeeService.searchFlexTimeApprover(params);
};

const useSearchFlexTimeApprover = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['EMPLOYEE_FLEX_TIME_APPROVER', params], () => searchFlexTimeApprover(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    // staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Search
const searchTimeRecordApprover = (params) => {
  return EmployeeService.searchTimeRecordApprover(params);
};

const useSearchTimeRecordApprover = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['EMPLOYEE_TIME_RECORD_APPROVER', params], () => searchTimeRecordApprover(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    // staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Get
const get = (Property) => {
  return EmployeeService.get(Property);
};

const useGet = (onSuccess, onError, Property) => {
  return useQuery(['EMPLOYEE', Property.EMPLOYEE_ID], () => get(Property), {
    onSuccess,
    onError,
    // cacheTime: 0,
    enabled: Property.EMPLOYEE_ID ? true : false,
  });
};

// - Update
const update = (dataItem) => {
  const data = EmployeeService.update(dataItem);
  return data;
};

const useUpdate = (onSuccess, onError) => {
  return useMutation(update, {
    onSuccess,
    onError,
  });
};

// - Delete
const deleteData = (Property) => {
  const data = EmployeeService.delete(Property);
  return data;
};

const useDelete = (onSuccess, onError) => {
  return useMutation(deleteData, {
    onSuccess,
    onError,
  });
};

export {
  useCreate,
  useCreatePassPro,
  useSearch,
  useSearchUserProbation,
  useSearchEmployeeByEmployeeCode,
  useSearchFlexTimeApprover,
  useSearchTimeRecordApprover,
  useSearchEmployeeIdByFlow,
  useGet,
  useUpdate,
  useDelete,
  useSearchApprover,
  useUpdateUserProbation,
};
