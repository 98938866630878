import { useQuery, useMutation } from "react-query";
import LeaveRemainDayService from "../../../services/LeaveRemainDayService";
// - Create
const create = (dataItem) => {
  const data = LeaveRemainDayService.create(dataItem);
  return data;
};

const useCreate = (onSuccess, onError) => {
  return useMutation(create, {
    onSuccess,
    onError,
  });
};

// - Search
const search = (params) => {
  return LeaveRemainDayService.search(params);
};

const useSearch = (onSuccess, onError, params, isFetchData) => {
  return useQuery(["LEAVE_REMAIN_DAY", params], () => search(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Get
const get = (Property) => {
  return LeaveRemainDayService.get(Property);
};

const useGet = (onSuccess, onError, Property) => {
  return useQuery(["LEAVE_REMAIN_DAY", Property.EMPLOYEE_ID], () => get(Property), {
    onSuccess,
    onError,
    // cacheTime: 0,
    enabled: Property.EMPLOYEE_ID ? true : false,
  });
};

// - Update
const update = (dataItem) => {
  const data = LeaveRemainDayService.update(dataItem);
  return data;
};

const useUpdate = (onSuccess, onError) => {
  return useMutation(update, {
    onSuccess,
    onError,
  });
};

// - Delete
const deleteData = (Property) => {
  const data = LeaveRemainDayService.delete(Property);
  return data;
};

const useDelete = (onSuccess, onError) => {
  return useMutation(deleteData, {
    onSuccess,
    onError,
  });
};

// - UpdateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId
const updateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId = (dataItem) => {
  const data = LeaveRemainDayService.updateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId(dataItem);
  return data;
};

const useUpdateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId = (onSuccess, onError) => {
  return useMutation(updateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId, {
    onSuccess,
    onError,
  });
};

export { useCreate, useSearch, useGet, useUpdate, useDelete , useUpdateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId };
