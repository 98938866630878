// ** Third Party Components
import toast from "react-hot-toast";

const ToastMessageSuccess = ({ message, title }) => {
  // toastr.options = {
  //   closeButton: true,
  //   debug: false,
  //   extendedTimeOut: '1000',
  //   hideDuration: '1000',
  //   hideEasing: 'linear',
  //   hideMethod: 'fadeOut',
  //   newestOnTop: true,
  //   positionClass: 'toast-top-right',
  //   preventDuplicates: false,
  //   progressBar: true,
  //   showDuration: '300',
  //   showEasing: 'swing',
  //   showMethod: 'fadeIn',
  //   timeOut: '5000'
  // }
  // return toastr.success(message, title)

  return toast.success(message, {
    duration: 4000,
    position: "top-center",
    style: {
      borderRadius: "10px",
    },
  });
};
const ToastMessageError = ({ message, title }) => {
  // toastr.options = {
  //   closeButton: true,
  //   debug: false,
  //   extendedTimeOut: '1000',
  //   hideDuration: '1000',
  //   hideEasing: 'linear',
  //   hideMethod: 'fadeOut',
  //   newestOnTop: true,
  //   positionClass: 'toast-top-right',
  //   preventDuplicates: false,
  //   progressBar: true,
  //   showDuration: '300',
  //   showEasing: 'swing',
  //   showMethod: 'fadeIn',
  //   timeOut: '10000'
  // }
  // return toastr.error(message, title)

  return toast.error(message, {
    duration: 4000,
    position: "top-center",
    style: {
      borderRadius: "10px",
    },
  });
};

const ToastMessageWarning = ({ message, title }) => {
  // toastr.options = {
  //   closeButton: true,
  //   debug: false,
  //   extendedTimeOut: '1000',
  //   hideDuration: '1000',
  //   hideEasing: 'linear',
  //   hideMethod: 'fadeOut',
  //   newestOnTop: true,
  //   positionClass: 'toast-top-right',
  //   preventDuplicates: false,
  //   progressBar: true,
  //   showDuration: '300',
  //   showEasing: 'swing',
  //   showMethod: 'fadeIn',
  //   timeOut: '10000'
  // }
  // return toastr.error(message, title)

  return toast.error(message, {
    duration: 7000,
    icon: "📢",
    position: "top-center",
    style: {
      borderRadius: "10px",
    },
  });
};

export { ToastMessageSuccess, ToastMessageError, ToastMessageWarning };
