import { lazy } from 'react';

const TimeRecordHistory = lazy(() => import('../../views/timeRecord/TimeRecordHistory/index'));
const TimeRecordApproval = lazy(() => import('../../views/timeRecord/TimeRecordApproval/index'));
const TimeRecordHrCheck = lazy(() => import('../../views/timeRecord/TimeRecordHRCheck/index'));
const TimeRecordRequest = lazy(() => import('../../views/timeRecord/TimeRecordRequest/index'));

const TimeRecordRoutes = [
  {
    path: '/time-record-history',
    element: <TimeRecordHistory />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/time-record-request',
    element: <TimeRecordRequest />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/time-record-approval',
    element: <TimeRecordApproval />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/time-record-hr-checker',
    element: <TimeRecordHrCheck />
    // meta: {
    //   publicRoute: true,
    // },
  }
];

export default TimeRecordRoutes;
