// ** React Imports
import { useRef, useState } from "react";

// ** Reactstrap Imports
import { Row, Col } from "reactstrap";

// ** Third Party Components
import { useForm } from "react-hook-form";

// ** Custom Components
import Breadcrumbs from "@components/breadcrumbs";

// ** Components
import RemainLeaveSearch from "./RemainLeaveSearch";
import RemainLeaveTableData from "./RemainLeaveTableData";

// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss";

const RemainLeave = () => {
  // ** useState
  const [isFetchData, setIsFetchData] = useState(true);
  const [isSearchDataForSearchForm, setIsSearchDataForSearchForm] =
    useState(false);

  // ** useRef
  const refTableData = useRef();
  const refRowSelected = useRef();

  // React Hook Form
  // - Search
  const {
    register,
    getValues,
    reset: resetAllFormSearch,
    control: controlFormSearch,
  } = useForm();

  // Functions
  const clearFilterFormSearch = () => {
    resetAllFormSearch({
      employeeName: "",
      employeeCode: "",
      employeeDept: "",
    });
  };

  return (
    <>
      <Breadcrumbs title="Remain Leave" data={[{ title: "Remain Leave" }]} />

      <Row>
        <Col sm="12">
          {
            <RemainLeaveSearch
              refTableData={refTableData}
              register={register}
              clearFilterFormSearch={clearFilterFormSearch}
              controlFormSearch={controlFormSearch}
              isSearchDataForSearchForm={isSearchDataForSearchForm}
              setIsSearchDataForSearchForm={setIsSearchDataForSearchForm}
            />
          }
        </Col>
        <Col sm="12">
          {
            <RemainLeaveTableData
              refTableData={refTableData}
              isFetchData={isFetchData}
              setIsFetchData={setIsFetchData}
              getValues={getValues}
              refRowSelected={refRowSelected}
              clearFilterFormSearch={clearFilterFormSearch}
              setIsSearchDataForSearchForm={setIsSearchDataForSearchForm}
            />
          }
        </Col>
      </Row>
    </>
  );
};

export default RemainLeave;
