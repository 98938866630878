// ** React Imports

// ** Custom hooks

// ** Custom Components
import defaultImg from '@src/assets/images/common/defaultImg.png';
import noImg from '@src/assets/images/common/no-image-2.jpg';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Container } from 'reactstrap';
import { ImageFromURL } from './ImageFromURL';

import Avatar from '@components/avatar';
import { Lightbox } from 'react-modal-image';

import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSkin } from '@hooks/useSkin';
import { Check, Download, Upload, X } from 'react-feather';

import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useSearchApprover,
  useSearchFlexTimeApprover,
  useSearchTimeRecordApprover,
} from '../../utility/hooks/react-query/useEmployeeData';
import { useGetDownload } from '../../utility/hooks/react-query/useLeaveData';
import { ToastMessageError } from './ToastMessage';

// ** Function
const getUrlParamSearch = (LEAVE_REQUEST_ID) => {
  // console.log(LEAVE_REQUEST_ID);
  let params = ``;
  params += `"LEAVE_REQUEST_ID":"${LEAVE_REQUEST_ID}"`;
  params = `{${params}}`;
  return params;
};

const getFlexTimeUrlParamSearch = (FLEX_TIME_REQUEST_ID) => {
  // console.log(LEAVE_REQUEST_ID);
  let params = ``;
  params += `"FLEX_TIME_REQUEST_ID":"${FLEX_TIME_REQUEST_ID}"`;
  params = `{${params}}`;
  return params;
};

const getTimeRecordUrlParamSearch = (TIME_RECORD_REQUEST_ID) => {
  // console.log(LEAVE_REQUEST_ID);
  let params = ``;
  params += `"TIME_RECORD_REQUEST_ID":"${TIME_RECORD_REQUEST_ID}"`;
  params = `{${params}}`;
  return params;
};

const GenerateSortingIndicator = ({ column }) => {
  return column.isSorted ? (
    column.isSortedDesc ? (
      <FontAwesomeIcon
        icon={faSortDown}
        style={{ marginLeft: 10 }}
        className='text-primary'
      />
    ) : (
      <FontAwesomeIcon
        icon={faSortUp}
        style={{ marginLeft: 10 }}
        className='text-primary'
      />
    )
  ) : column.filterable ? (
    <FontAwesomeIcon
      icon={faSort}
      style={{ marginLeft: 10 }}
      className='text-secondary'
    />
  ) : (
    ''
  );
};

const TextBoldColumn = (cell) => {
  return <b>{cell.value ? cell.value : ''}</b>;
};

const NormalColumn = (cell) => {
  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  if (cell.column.Header == 'Leave Type') {
    return cell.value ? t(cell.value) : '';
  }

  return typeof cell.value === 'undefined' || cell.value === null ? '' : cell.value;
};

const DateRangeColumn = (cell) => {
  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  let dateRangeArray = cell?.value?.split(' ');

  dateRangeArray[1] = t('ถึง');

  const dateRangeString = dateRangeArray.join(' ');

  if (cell.column.Header == 'วันที่ลา') {
    return cell.value ? dateRangeString : '';
  }

  return cell.value ? dateRangeString : '';
};

const FileColumn = ({ cell, onClickUploadFile, isShowUploadFileModel, setIsShowUploadFileModel }) => {
  // console.log(cell.row.original);
  const [file, setFile] = useState(null);

  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  const location = useLocation();

  const onSuccessGetDownloadFile = async (data) => {
    if (data) {
      FileDownload(data.data, cell.row.original.LEAVE_REQUEST_FILE_UPLOAD_NAME);

      const message = {
        message: data.data.Message,
        title: 'Download File',
      };

      //  ToastMessageSuccess(message);
    } else {
      // refFocus.current.focus();

      const message = {
        title: 'Download File',
        message: data.data.Message,
      };

      ToastMessageError(message);
    }
  };

  const onErrorGetDownloadFile = (error) => {
    // refFocus.current.focus();

    const message = {
      title: 'Download File',
      message: error.Message,
    };

    ToastMessageError(message);
  };

  const { mutateAsync: getDownloadFile, isLoading: isLoadingGetDownloadFile } = useGetDownload(
    onSuccessGetDownloadFile,
    onErrorGetDownloadFile
  );
  //#endregion react-query => get-data

  // Functions
  const handleDownloadFile = (e) => {
    // console.log(e);

    const dataItem = {
      FILE_NAME: cell.row.original.LEAVE_REQUEST_FILE_UPLOAD_NAME,
      FILE_PATH: cell.row.original.LEAVE_REQUEST_FILE_UPLOAD_PATH,
    };

    getDownloadFile(dataItem);
  };

  if (cell.value) {
    return (
      <Container
        fluid='xl'
        onClick={(e) => handleDownloadFile(e)}
      >
        <Button.Ripple
          className='round'
          outline
          color='success'
        >
          <Download size={16} />
          <span className='align-middle ms-25'> {t('File Uploaded')}</span>
        </Button.Ripple>

        {/* <Button color="success" disabled>
          {t("File Uploaded")}
        </Button>

        <Button
          color="success"
          outline
          className="ms-1"
          onClick={(e) => handleDownloadFile(e)}
        >
          <Download size={16} />
        </Button> */}
      </Container>
    );
  } else {
    return (
      <>
        <Container fluid='xl'>
          {location.pathname == '/leave-history' ? (
            <>
              <Button.Ripple
                className='round'
                outline
                color='warning'
                onClick={(e) => {
                  e.preventDefault();
                  onClickUploadFile(cell.row.original);
                }}
              >
                <Upload size={16} />
                <span className='align-middle ms-25'>{t('Upload File')}</span>
              </Button.Ripple>

              {/* <Button color="warning" outline disabled>
                {t("Upload File")}
              </Button>
              <Button
                color="warning"
                outline
                className="ms-1"
                onClick={(e) => {
                  e.preventDefault();
                  onClickUploadFile(cell.row.original);
                }}
              >
                <Upload size={16} />
              </Button> */}
            </>
          ) : (
            <Button
              color='warning'
              outline
              disabled
            >
              {t('No File Upload')}
            </Button>
          )}
        </Container>
      </>
    );
  }
};

const ListColumn = (cell) => {
  const { skin } = useSkin();

  const [approval, setApproval] = useState([]);

  const onSuccessSearchData = (data) => {
    // setIsFetchData(false);

    if (data.data && data.data.Status == true) {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      setApproval(data.data.ResultOnDb);

      //   ToastMessageSuccess(message);
    } else {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      // ToastMessageError(message);
    }
  };

  const onErrorSearchData = (error) => {
    // setIsFetchData(false);

    const message = {
      title: 'Search Employee',
      message: error.Message,
    };
    // ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchApprover(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(cell.row.original.LEAVE_REQUEST_ID),
    cell.value.length >= 0
  );

  // console.log(cell.row.original.IS_APPROVED);
  // console.log(data.data?.ResultOnDb);
  // console.log("reset rsult");

  let result = [];

  // console.log(cell.value);

  if (cell.row.original.IS_APPROVED == 0) {
    cell.value.map((elem) => {
      result.push({
        APPROVER_ID: elem,
        APPROVAL_STATUS_ID: 0,
      });
    });
  } else {
    data?.data?.ResultOnDb.map((elem) => {
      result.push({
        APPROVER_ID: elem.APPROVAL_BY_APPROVER_EMPLOYEE_ID,
        APPROVAL_STATUS_ID: 1,
      });
    });
  }

  // console.log(result);

  result.map((el) => {
    approval.map((ele) => {
      if (el.APPROVER_ID.toString().toLowerCase() == ele.APPROVAL_BY_APPROVER_EMPLOYEE_ID.toLowerCase()) {
        el.APPROVAL_STATUS_ID = ele.APPROVAL_STATUS_ID;
      }
    });
  });

  // cell.row.original.APPROVER = result;

  // console.log(result);
  return (
    <ol>
      {result.map(({ APPROVER_ID, APPROVAL_STATUS_ID }, index) => {
        // console.log("asdasd", APPROVER_ID);
        // console.log("qweqwewq", APPROVAL_STATUS_ID);
        return (
          <li key={`${APPROVER_ID}-${APPROVAL_STATUS_ID}-${index}`}>
            <Badge
              pill
              color={
                (skin === 'dark' ? 'light-' : '') +
                (APPROVAL_STATUS_ID == '1' ? 'success' : APPROVAL_STATUS_ID == '2' ? 'danger' : 'secondary')
              }
              style={{
                opacity: APPROVAL_STATUS_ID == '1' ? '1' : APPROVAL_STATUS_ID == '2' ? '1' : '0.6',
              }}
            >
              {APPROVAL_STATUS_ID == '1' ? (
                <Check size={20} />
              ) : APPROVAL_STATUS_ID == '2' ? (
                <X size={20} />
              ) : (
                <Check size={20} />
              )}
            </Badge>
            {` : ${APPROVER_ID}`}
          </li>
        );
      })}
    </ol>
  );
};

const FlexTimeListColumn = (cell) => {
  const { skin } = useSkin();

  const [approval, setApproval] = useState([]);

  const onSuccessSearchData = (data) => {
    // setIsFetchData(false);

    if (data.data && data.data.Status == true) {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      setApproval(data.data.ResultOnDb);

      //   ToastMessageSuccess(message);
    } else {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      // ToastMessageError(message);
    }
  };

  const onErrorSearchData = (error) => {
    // setIsFetchData(false);

    const message = {
      title: 'Search Employee',
      message: error.Message,
    };
    // ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchFlexTimeApprover(
    onSuccessSearchData,
    onErrorSearchData,
    getFlexTimeUrlParamSearch(cell.row.original.FLEX_TIME_REQUEST_ID),
    cell.value.length >= 0
  );

  // console.log(cell.row.original.LEAVE_REQUEST_ID);
  // console.log("reset rsult");

  let result = [];

  // console.log(cell.value);

  cell.value.map((elem) => {
    result.push({
      APPROVER_ID: elem,
      APPROVAL_STATUS_ID: 0,
    });
  });

  result.map((el) => {
    approval.map((ele) => {
      if (el.APPROVER_ID.toString().toLowerCase() == ele.FLEX_TIME_APPROVAL_BY.toLowerCase()) {
        el.APPROVAL_STATUS_ID = ele.FLEX_TIME_APPROVAL_STATUS;
      }
    });
  });

  // cell.row.original.APPROVER = result;

  // console.log(result);
  return (
    <ol>
      {result.map(({ APPROVER_ID, APPROVAL_STATUS_ID }, index) => {
        // console.log("asdasd", APPROVER_ID);
        // console.log("qweqwewq", APPROVAL_STATUS_ID);
        return (
          <li key={`${APPROVER_ID}-${APPROVAL_STATUS_ID}-${index}`}>
            <Badge
              pill
              color={
                (skin === 'dark' ? 'light-' : '') +
                (APPROVAL_STATUS_ID == '1' ? 'success' : APPROVAL_STATUS_ID == '2' ? 'danger' : 'secondary')
              }
              style={{
                opacity: APPROVAL_STATUS_ID == '1' ? '1' : APPROVAL_STATUS_ID == '2' ? '1' : '0.6',
              }}
            >
              {APPROVAL_STATUS_ID == '1' ? (
                <Check size={20} />
              ) : APPROVAL_STATUS_ID == '2' ? (
                <X size={20} />
              ) : (
                <Check size={20} />
              )}
            </Badge>
            {` : ${APPROVER_ID}`}
          </li>
        );
      })}
    </ol>
  );
};

const TimeRecordListColumn = (cell) => {
  const { skin } = useSkin();

  const [approval, setApproval] = useState([]);

  const onSuccessSearchData = (data) => {
    // setIsFetchData(false);

    if (data.data && data.data.Status == true) {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      setApproval(data.data.ResultOnDb);

      //   ToastMessageSuccess(message);
    } else {
      const message = {
        title: 'Search Employee',
        message: data.data.Message,
      };

      // ToastMessageError(message);
    }
  };

  const onErrorSearchData = (error) => {
    // setIsFetchData(false);

    const message = {
      title: 'Search Employee',
      message: error.Message,
    };
    // ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchTimeRecordApprover(
    onSuccessSearchData,
    onErrorSearchData,
    getTimeRecordUrlParamSearch(cell.row.original.TIME_RECORD_REQUEST_ID),
    cell.value.length >= 0
  );

  // console.log(cell.row.original.LEAVE_REQUEST_ID);
  // console.log("reset rsult");

  let result = [];

  // console.log(cell.value);

  cell.value.map((elem) => {
    result.push({
      APPROVER_ID: elem,
      APPROVAL_STATUS_ID: 0,
    });
  });

  result.map((el) => {
    approval.map((ele) => {
      if (el.APPROVER_ID.toString().toLowerCase() == ele.TIME_RECORD_APPROVAL_BY.toLowerCase()) {
        el.APPROVAL_STATUS_ID = ele.TIME_RECORD_APPROVAL_STATUS;
      }
    });
  });

  // cell.row.original.APPROVER = result;

  return (
    <ol>
      {result.map(({ APPROVER_ID, APPROVAL_STATUS_ID }, index) => {
        return (
          <li key={`${APPROVER_ID}-${APPROVAL_STATUS_ID}-${index}`}>
            <Badge
              pill
              color={
                (skin === 'dark' ? 'light-' : '') +
                (APPROVAL_STATUS_ID == '1' ? 'success' : APPROVAL_STATUS_ID == '2' ? 'danger' : 'secondary')
              }
              style={{
                opacity: APPROVAL_STATUS_ID == '1' ? '1' : APPROVAL_STATUS_ID == '2' ? '1' : '0.6',
              }}
            >
              {APPROVAL_STATUS_ID == '1' ? (
                <Check size={20} />
              ) : APPROVAL_STATUS_ID == '2' ? (
                <X size={20} />
              ) : (
                <Check size={20} />
              )}
            </Badge>
            {` : ${APPROVER_ID}`}
          </li>
        );
      })}
    </ol>
  );
};

const NormalWithoutNoneColumn = (cell) => {
  return cell.value && cell.value !== 'None' ? cell.value : '';
};

const CurrencyWithoutNoneColumn = (cell) => {
  return cell.value && cell.value !== 'None'
    ? Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(Number(cell.value))
    : '';
};

const DoubleWithoutTrailingZerosColumn = (cell) => {
  return cell.value && cell.value !== 'None' ? Number(cell.value).toString() : '';
};

const BadgeColumn = (cell) => {
  return (
    <Badge
      color='light-warning'
      className='rounded p-75'
    >
      ENG
    </Badge>
  );
};

const AvatarColumn = (cell) => {
  return (
    <Avatar
      color='light-primary'
      content='ENG'
      initials
    />
  );
};

// const ProgressColumn = ({ cell }) => {
//   // ** Color
//   const valueColors = ['danger', 'warning', 'info', 'success']

//   const value = Number(cell.PROGRESS) / 25
//   let color

//   if (value <= 1) {
//     color = valueColors[0]
//   } else if (value <= 2) {
//     color = valueColors[1]
//   } else if (value <= 3) {
//     color = valueColors[2]
//   } else {
//     color = valueColors[3]
//   }

//   // ** Image
//   const [pic1, setPic1] = useState('')
//   const [pic2, setPic2] = useState('')
//   const [pic3, setPic3] = useState('')
//   const [pic4, setPic4] = useState('')

//   const data = [
//     {
//       no: 1,
//       title: 'PC | ' + cell.UPDATE_BY,
//       img: pic1 || defaultPersonImg,
//       imgHeight: 30,
//       imgWidth: 30,
//       iscompletedstep: cell.UPDATE_BY ? '1' : '0'
//     },
//     {
//       no: 2,
//       title:
//         'ENG' + (cell?.UPDATE_BY_STEP_2 ? ' | ' + cell.UPDATE_BY_STEP_2 : ''),
//       img: pic2 || defaultPersonImg,
//       imgHeight: 30,
//       imgWidth: 30,
//       iscompletedstep: cell?.UPDATE_BY_STEP_2 ? '1' : '0'
//     },
//     {
//       no: 3,
//       title:
//         'MFG' + (cell?.UPDATE_BY_STEP_3 ? ' | ' + cell.UPDATE_BY_STEP_3 : ''),
//       img: pic3 || defaultPersonImg,
//       imgHeight: 30,
//       imgWidth: 30,
//       iscompletedstep: cell?.UPDATE_BY_STEP_3 ? '1' : '0'
//     },
//     {
//       no: 4,
//       title:
//         'PC' + (cell?.UPDATE_BY_STEP_4 ? ' | ' + cell.UPDATE_BY_STEP_4 : ''),
//       img: pic4 || defaultPersonImg,
//       imgHeight: 30,
//       imgWidth: 30,
//       iscompletedstep: cell?.UPDATE_BY_STEP_4 ? '1' : '0'
//     }
//   ]

//   // ** Effect
//   useEffect(() => {
//     // setImageFromUrl(
//     //   process.env.REACT_APP_IMAGE_EMPLOYEE_ROOT_PATH +
//     //     cell.row.original.UPDATE_BY +
//     //     '.png',
//     //   setPic1
//     // )

//     setImageFromUrl(
//       `${process.env.REACT_APP_IMAGE_EMPLOYEE_ROOT_PATH}/${cell.UPDATE_BY}.jpg`,
//       setPic1
//     )
//   }, [])

//   // ** Rander
//   return (
//     <>
//       <AvatarGroupWithKey data={data} id={`sct-progress-${cell.SCT_ID}`} />
//       <Progress
//         value={cell.PROGRESS}
//         className={`w-100 progress-bar-${color}`}
//         style={{ marginTop: 7 }}
//       />
//       {/* {`${cell.PROGRESS}%`}
//       </Progress> */}
//     </>
//   )
// }

const StatusColumn = (cell) => {
  // console.log(cell.row.original.IS_APPROVED);
  const { skin } = useSkin();

  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  if (cell.row.original.IS_APPROVED === 1) {
    return (
      <Badge
        pill
        color={(skin === 'dark' ? 'light-' : '') + 'success'}
      >
        {t('Approved')}
      </Badge>
    );
  }

  return (
    <Badge
      pill
      color={
        (skin === 'dark' ? 'light-' : '') + (cell.value == '0' ? 'secondary' : cell.value == '1' ? 'success' : 'danger')
      }
    >
      {cell.value == '0' ? t('Waiting') : cell.value == '1' ? t('Approved') : t('Rejected')}
    </Badge>
  );
};

const InuseColumn = (cell) => {
  // console.log(cell.row.original);
  const { skin } = useSkin();

  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  return (
    <Badge
      pill
      color={(skin === 'dark' ? 'light-' : '') + (cell.value == '0' ? 'danger' : 'success')}
    >
      {cell.value == '0' ? t('Cancel') : t('Use')}
    </Badge>
  );
};

const PassProColumn = (cell) => {
  // console.log(cell.value);
  const { skin } = useSkin();

  // const { t } = useTranslation();
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data',
  });

  return (
    <Badge
      pill
      color={(skin === 'dark' ? 'light-' : '') + (cell.value == 0 || cell.value === null ? 'danger' : 'success')}
    >
      {cell.value == 0 || cell.value === null ? t('No') : t('Yes')}
    </Badge>
  );
};

const StatusOrderColumn = (cell) => {
  const { skin } = useSkin();

  return (
    <Badge
      pill
      color={
        (skin === 'dark' ? 'light-' : '') +
        (cell.value == '1' ? 'primary' : cell.value == '2' ? 'info' : cell.value == '3' ? 'success' : 'secondary')
      }
    >
      {cell.value == '1'
        ? 'Pending Order'
        : cell.value == '2'
        ? 'On Process'
        : cell.value == '3'
        ? 'Success'
        : 'Cancel'}
    </Badge>
  );
};

const ButtonSelectColumn = ({ cell }) => {
  const { skin } = useSkin();

  return (
    <Button.Ripple
      outline
      color='success'
      size='sm'
      onClick={(e) => {
        e.preventDefault();
        setFlow(cellProps.row.original);
        setIsShowSelectFlowProcessModel(false);
        if (setIsClickChangeFlow) setIsClickChangeFlow(true);
      }}
    >
      <Check size={14} />
      <span
        className='align-middle ms-25'
        style={{ margin: 0 }}
      >
        Select
      </span>
    </Button.Ripple>
  );
};

const YesNoColumn = (cell) => {
  return <p className={'my-0 ' + (cell.value == '0' && ' text-danger')}>{cell.value == '1' ? 'Yes' : 'No'}</p>;
};

const NeedNoNeedColumn = (cell) => {
  return <p className={'my-0 ' + (cell.value == '0' && ' text-danger')}>{cell.value == '1' ? 'Need' : 'No need'}</p>;
};

const ImageColumn = ({ cell }) => {
  // ** State
  const [small, setSmall] = useState('');
  const [large, setLarge] = useState('');
  const [isOpenModel, setIsOpenModel] = useState(false);

  // ** Effect
  useEffect(() => {
    if (cell.IMAGE_PATH !== 'None') ImageFromURL(cell.IMAGE_PATH, setSmall, setLarge);
  }, []);

  return (
    <>
      {cell.IMAGE_PATH !== 'None' ? (
        small === '' ? (
          <img
            src={defaultImg}
            width='40'
            height='35'
            // onClick={() => setIsOpenModel(true)}
            loading='lazy'
          />
        ) : (
          <img
            src={small}
            width='40'
            height='35'
            onClick={() => setIsOpenModel(true)}
            loading='lazy'
          />
        )
      ) : (
        <img
          src={noImg}
          width='40'
          height='35'
          // onClick={() => setIsOpenModel(true)}
          loading='lazy'
        />
      )}

      {isOpenModel && (
        <Lightbox
          // medium={large}
          large={large}
          alt={cell.MATERIAL_INTERNAL_CODE}
          onClose={() => setIsOpenModel(false)}
          showRotate
        />
      )}
    </>
  );
};

export {
  AvatarColumn,
  BadgeColumn,
  CurrencyWithoutNoneColumn,
  DoubleWithoutTrailingZerosColumn,
  FileColumn,
  FlexTimeListColumn,
  GenerateSortingIndicator,
  ImageColumn,
  InuseColumn,
  ListColumn,
  NeedNoNeedColumn,
  NormalColumn,
  NormalWithoutNoneColumn,
  PassProColumn,
  StatusColumn,
  StatusOrderColumn,
  TextBoldColumn,
  YesNoColumn,
  DateRangeColumn,
  TimeRecordListColumn,
};
