// ** Icons Import
import { Children } from 'react';
import { Home, Circle, FilePlus, CheckSquare, Monitor, Search } from 'react-feather';
// import { FaBeer } from "react-icons/fa";

export default [
  {
    header: 'Time Record Menu',
    meta: {
      publicMenuGroup: false
    }
  },

  {
    id: '278',
    title: 'Time Record Request',
    icon: <FilePlus size={12} />,
    // icon: <FaBeer size={12} />,
    navLink: '/time-record-request',
    meta: {
      publicGroup: false
    }
  },
  {
    id: '279',
    title: 'Time Record History',
    icon: <Search size={12} />,
    navLink: '/time-record-history',
    meta: {
      publicGroup: false
    }
  },
  {
    id: '280',
    title: 'Time Record Approval',
    icon: <CheckSquare size={12} />,
    navLink: '/time-record-approval'
  },

  {
    id: '281',
    title: 'Time Record Checker',
    icon: <Monitor size={12} />,
    navLink: '/time-record-hr-checker'
  }
];
