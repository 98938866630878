// ** Icons Import
import { Children } from 'react';
import { Home, Circle, FilePlus, CheckSquare, Monitor, Search } from 'react-feather';
// import { FaBeer } from "react-icons/fa";

export default [
  {
    header: 'Flex Time Menu',
    meta: {
      publicMenuGroup: false
    }
  },

  {
    id: '216',
    title: 'Flex Time Request',
    icon: <FilePlus size={12} />,
    // icon: <FaBeer size={12} />,
    navLink: '/flex-time-request',
    meta: {
      publicGroup: false
    }
  },
  {
    id: '223',
    title: 'Flex Time History',
    icon: <Search size={12} />,
    navLink: '/flex-time-history',
    meta: {
      publicGroup: false
    }
  },
  {
    id: '217',
    title: 'Flex Time Approval',
    icon: <CheckSquare size={12} />,
    navLink: '/flex-time-approval'
  },

  {
    id: '218',
    title: 'Check Employee Flex Time',
    icon: <Monitor size={12} />,
    navLink: '/flex-time-hr'
  },
  {
    id: '219',
    title: 'Subordinate Flex Time',
    icon: <Monitor size={12} />,
    navLink: '/flex-time-manager'
  }
];
