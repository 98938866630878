import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
// ** Images
import deleteImg from "@src/assets/images/common/undraw_clean_up_re_504g.svg";
import editImg from "@src/assets/images/common/undraw_notify_re_65on.svg";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({
  show,
  onConfirmClick,
  onCloseClick,
  isLoading,
  isDelete,
}) => {
  // const { t } = useTranslation();
  const { t } = useTranslation("Modal", { keyPrefix: "Utility.Modal" });

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={false}>
      <ModalBody className="py-3 px-5 custom-width-400">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <img
                src={isDelete ? deleteImg : editImg}
                alt=""
                width={150}
                // height={100}
                className=" mb-2"
              />
              <h2>{t("Are you sure")} ?</h2>
              {/* เมื่อคุณยืนยันแล้วจะไม่สามารถแก้ไขได้ */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <Button
                type="button"
                onClick={onConfirmClick}
                color={isDelete ? "danger" : "success"}
                disabled={isLoading}
                outline={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" />
                    <span className="ms-50">
                      {" "}
                      {isDelete ? "Deleting" : "Saving"}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    {t("Yes")}, {isDelete ? t("delete") : t("Submit")}
                  </>
                )}
              </Button>
              <Button
                type="button"
                color="secondary"
                className="ms-2"
                onClick={onCloseClick}
                disabled={isLoading}
                outline
              >
                {t("No, cancel")}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
};

export default ConfirmModal;
