// ** React Imports
import { Fragment } from "react";

// ** Custom Components
import NavbarUser from "./NavbarUser";
import NavbarBookmarks from "./NavbarBookmarks";

const ThemeNavbar = (props) => {
  // ** Props
  const {
    skin,
    setSkin,
    setMenuVisibility,
    userGroup,
    setUserGroup,
    isFetchData,
    setIsFetchData,
  } = props;
  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      <NavbarUser
        skin={skin}
        setSkin={setSkin}
        userGroup={userGroup}
        setUserGroup={setUserGroup}
        isFetchData={isFetchData}
        setIsFetchData={setIsFetchData}
      />
    </Fragment>
  );
};

export default ThemeNavbar;
