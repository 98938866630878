import { getUserData } from '../utility/Utils';

const getUserName = () => {
  try {
    return getUserData().username;
  } catch (error) {
    return '';
  }
};

const getUserGroupName = () => {
  try {
    return getUserData().userGroup;
  } catch (error) {
    return '';
  }
};

const getEmpPosition = () => {
  try {
    return getUserData().JOB_GRADE;
  } catch (error) {
    return '';
  }
};

const getEmpDept = () => {
  try {
    return getUserData().DEPARTMENT_NAME;
  } catch (error) {
    return '';
  }
};

const getEmployeeCode = () => {
  try {
    return getUserData().EMPLOYEE_CODE;
  } catch (error) {
    return '';
  }
};

export { getUserName, getUserGroupName, getEmpPosition, getEmpDept, getEmployeeCode };
