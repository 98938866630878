import { lazy } from 'react';
import FlexTimeCheckSubordinate from '../../views/flexTimeSystems/FlexTimeCheckSubordinate';

const FlexTimeHistory = lazy(() => import('../../views/flexTimeSystems/FlexTimeHistory/index'));
const FlexTimeApproval = lazy(() => import('../../views/flexTimeSystems/FlexTimeApproval/index'));
const FlexTimeHrCheck = lazy(() => import('../../views/flexTimeSystems/FlexTimeHrCheck/index'));
const FlexTimeRequest = lazy(() => import('../../views/flexTimeSystems/FlexTimeRequest/index'));

const FlexTimeSystemRoutes = [
  {
    path: '/flex-time-history',
    element: <FlexTimeHistory />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/flex-time-request',
    element: <FlexTimeRequest />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/flex-time-approval',
    element: <FlexTimeApproval />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/flex-time-hr',
    element: <FlexTimeHrCheck />
    // meta: {
    //   publicRoute: true,
    // },
  },
  {
    path: '/flex-time-manager',
    element: <FlexTimeCheckSubordinate />
    // meta: {
    //   publicRoute: true,
    // },
  }
];

export default FlexTimeSystemRoutes;
