import api_request from "../utility/api-request";
import LeaveRemainDayAPI from "../api/LeaveRemainDayAPI";

export default class LeaveRemainDayService {
  static create(Property) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/create`,
      method: "POST",
      data: Property,
    });
  }

  static search(params) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/searchLeaveRemainDay`,
      params: { data: params },
      method: "GET",
    });
  }

  static get(Property) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/getAll`,
      params: { data: JSON.stringify(Property) },
      method: "GET",
    });
  }

  static update(Property) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/update`,
      method: "PATCH",
      data: Property,
    });
  }
  static updateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId(Property) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/updateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId`,
      method: "POST",
      data: Property,
    });
  }

  static delete(Property) {
    return api_request({
      url: `${LeaveRemainDayAPI.API_ROOT_URL}/deleteLeaveRemainDay`,
      data: Property,
      method: "DELETE",
    });
  }
}
