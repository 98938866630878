import { useTranslation } from 'react-i18next';

const MatchingRegularExpression = {
  // value: /^(?!.* {2})[a-zA-Z0-9-,().][a-zA-Z0-9 -,().]+[a-zA-Z0-9]$/,
  // message:
  //   "Match with a-zA-Z0-9 -,(). only and Do't multiple spaces , Start and End with space",
};

const NumberOnlyPattern = {
  value: /^[0-9]+$/i,
  message: 'Please enter numbers (ex. 1,2,3) only.'
};

const UpperCaseCharacterPattern = {
  value: /^[A-Z]*$/,
  message: 'Please enter uppercase letters (ex. A,B,C) only.'
};

const UpperCaseCharacterAndNumberPattern = {
  value: /^[A-Z0-9]*$/,
  message: 'Please enter uppercase letters (ex. A,B,C) or numbers (ex. 1,2,3) only.'
};

const DecimalPattern = {
  value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
  message: 'Please enter decimal (ex. 0.125 , 0.458 , 25.456) only.'
};

const NumberAndDecimalPattern = () => {
  const { t } = useTranslation('Modal', { keyPrefix: 'Utility.Modal' });

  return {
    value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
    message: t('Please enter numbers or decimal')
  };
};

export {
  DecimalPattern,
  MatchingRegularExpression,
  NumberAndDecimalPattern,
  NumberOnlyPattern,
  UpperCaseCharacterAndNumberPattern,
  UpperCaseCharacterPattern
};
