// ** Third Party Components
import { components } from 'react-select';
import { X, Check, ShoppingBag, ShoppingCart, Circle } from 'react-feather';
import { useTranslation } from 'react-i18next';

const timeLeaveArr = [
  // { value: "08.30-10.30", label: "08.30 น. - 10.30 น. (0.25 วัน)" },
  { value: '08.30-12.30', label: '08.30 น. - 12.30 น. (0.5 วัน)' },
  // { value: "15.30-17.30", label: "15.30 น. - 17.30 น. (0.25 วัน)" },
  { value: '12.30-17.30', label: '12.30 น. - 17.30 น. (0.5 วัน)' },
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  { value: '20.30-00.30', label: '20.30 น. - 00.30 น. (0.5 วัน)' },
  { value: '00.30-05.30', label: '00.30 น. - 05.30 น. (0.5 วัน)' },
  { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const timeLeaveArrWFH = [
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const oneDayTimeLeaveArr = [
  // { value: "08.30-10.30", label: "08.30 น. - 10.30 น. (0.25 วัน)" },
  { value: '08.30-12.30', label: '08.30 น. - 12.30 น. (0.5 วัน)' },
  // { value: "15.30-17.30", label: "15.30 น. - 17.30 น. (0.25 วัน)" },
  { value: '12.30-17.30', label: '12.30 น. - 17.30 น. (0.5 วัน)' },
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  { value: '20.30-00.30', label: '20.30 น. - 00.30 น. (0.5 วัน)' },
  { value: '00.30-05.30', label: '00.30 น. - 05.30 น. (0.5 วัน)' },
  { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const oneDayTimeLeaveArrWithFlexTimeTypeFaster = [
  // { value: "08.30-10.30", label: "08.30 น. - 10.30 น. (0.25 วัน)" },
  { value: '07.30-11.30', label: '07.30 น. - 11.30 น. (0.5 วัน)' },
  // { value: "15.30-17.30", label: "15.30 น. - 17.30 น. (0.25 วัน)" },
  { value: '11.30-16.30', label: '11.30 น. - 16.30 น. (0.5 วัน)' },
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  // { value: '20.30-00.30', label: '20.30 น. - 00.30 น. (0.5 วัน)' },
  // { value: '00.30-05.30', label: '00.30 น. - 05.30 น. (0.5 วัน)' },
  // { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const oneDayTimeLeaveArrWithFlexTimeTypeSlower = [
  // { value: "08.30-10.30", label: "08.30 น. - 10.30 น. (0.25 วัน)" },
  { value: '09.30-13.30', label: '09.30 น. - 13.30 น. (0.5 วัน)' },
  // { value: "15.30-17.30", label: "15.30 น. - 17.30 น. (0.25 วัน)" },
  { value: '13.30-18.30', label: '13.30 น. - 18.30 น. (0.5 วัน)' },
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  // { value: '20.30-00.30', label: '20.30 น. - 00.30 น. (0.5 วัน)' },
  // { value: '00.30-05.30', label: '00.30 น. - 05.30 น. (0.5 วัน)' },
  // { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const multipleDayTimeLeaveArr = [
  { value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' },
  { value: '20.30-05.30', label: '20.30 น. - 05.30 น. (1 วัน)' },
];

const timeLeaveArrM2L = [{ value: '08.30-17.30', label: '08.30 น. - 17.30 น. (1 วัน)' }];

const OrderStatusOption = [
  {
    value: '1',
    label: 'Pending Order',
    icon: ShoppingCart,
  },
  {
    value: '2',
    label: 'On Process',
    icon: ShoppingBag,
  },
  {
    value: '3',
    label: 'Success',
    icon: Check,
  },
  {
    value: '4',
    label: 'Cancel',
    icon: X,
  },
];

const OrderStatusOptionOnEdit = [
  {
    value: '1',
    label: 'Pending Order',
    icon: ShoppingCart,
  },
  {
    value: '2',
    label: 'On Process',
    icon: ShoppingBag,
  },
  {
    value: '3',
    label: 'Success',
    icon: Check,
  },
];

const HrCheckOption = [
  {
    value: 'checked',
    label: 'ตรวจสอบแล้ว / Checked',
    icon: Check,
  },
  {
    value: 'notCheck',
    label: 'ยังไม่ตรวจสอบ / Not-check',
    icon: X,
  },
  {
    value: 'all',
    label: 'ทั้งหมด / All',
    icon: Circle,
  },
];

const TimeRecordHrCheckOption = [
  {
    value: 1,
    label: 'ตรวจสอบแล้ว / Checked',
    icon: Check,
  },
  {
    value: 0,
    label: 'ยังไม่ตรวจสอบ / Not-check',
    icon: X,
  },
  {
    value: 'all',
    label: 'ทั้งหมด / All',
    icon: Circle,
  },
];

const StatusOption = [
  {
    value: '1',
    label: 'อนุมัติแล้ว / Approved',
    icon: Check,
  },
  {
    value: '2',
    label: 'ไม่อนุมัติ / Rejected',
    icon: X,
  },
  {
    value: '3',
    label: 'รอการอนุมัติ / Waiting',
    icon: Circle,
  },
];

const InuseOption = [
  {
    value: '1',
    label: 'ใช้งาน / Use',
    icon: Check,
  },
  {
    value: '0',
    label: 'ยกเลิก / Cancel',
    icon: X,
  },
];

const PassProOption = [
  {
    value: 1,
    label: 'Yes',
    icon: Check,
  },
  {
    value: 0,
    label: 'No',
    icon: X,
  },
];

const QuestionOption = [
  {
    value: '1',
    label: 'Yes',
    icon: Check,
  },
  {
    value: '0',
    label: 'No',
    icon: X,
  },
];

const OptionComponent = ({ data, ...props }) => {
  const Icon = data.icon;

  if (!Icon) {
    return (
      <components.Option {...props}>
        {/* <Icon className="me-50" size={14} /> */}
        {data.label}
      </components.Option>
    );
  } else {
    return (
      <components.Option {...props}>
        <Icon
          className='me-50'
          size={14}
        />
        {data.label}
      </components.Option>
    );
  }
};

export {
  timeLeaveArr,
  timeLeaveArrWFH,
  timeLeaveArrM2L,
  OptionComponent,
  StatusOption,
  QuestionOption,
  OrderStatusOption,
  OrderStatusOptionOnEdit,
  InuseOption,
  HrCheckOption,
  PassProOption,
  multipleDayTimeLeaveArr,
  oneDayTimeLeaveArr,
  oneDayTimeLeaveArrWithFlexTimeTypeFaster,
  oneDayTimeLeaveArrWithFlexTimeTypeSlower,
  TimeRecordHrCheckOption,
};
