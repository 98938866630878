// ** React Imports
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

// ** Custom Components
// import Avatar from "@components/avatar";
import { Avatar, Grid } from '@nextui-org/react';
// ** Utils
import { isUserLoggedIn } from '@utils';

// ** Store & Actions
import { useDispatch } from 'react-redux';
import { handleLogout } from '@store/authentication';
// ** Third Party Components
import { Key, Power } from 'react-feather';

import AsyncSelect from 'react-select/async';
import { getUserName } from '../../../../auth/LoginUser';

import UserGroupDropDown from './UserGroupDropdown';

// ** Reactstrap Imports
import {
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Label,
} from 'reactstrap';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg';
import { ImageFromUrlRawData, setImageFromUrl } from '../../../../components/common/ImageFromURL';
import { getUserData } from '../../../../utility/Utils';

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setImageFromUrl(`${getUserName()}`, setImage);
    }
  }, []);

  return (
    <UncontrolledDropdown
      tag='li'
      className='dropdown-user nav-item'
      size='sm'
    >
      <DropdownToggle
        href='/'
        tag='a'
        className='nav-link dropdown-user-link'
        onClick={(e) => e.preventDefault()}
      >
        <Avatar
          // css={{ size: '$17' }}
          size='lg'
          zoomed
          src={image}
          color='gradient'
          bordered
        />
      </DropdownToggle>

      <DropdownMenu end>
        {/* <UserGroupDropDown /> */}
        <DropdownItem
          tag={Link}
          to='/pages/reset-password-basic'
        >
          <Key
            size={14}
            className='me-75'
          />
          <span className='align-middle'>Change Password</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag={Link}
          to='/login'
          onClick={() => dispatch(handleLogout())}
        >
          <Power
            size={14}
            className='me-75'
          />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
