import React, { useState, useEffect } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ToastMessageError, ToastMessageWarning } from '../../../../components/common/ToastMessage';

import { useSearchHrChecker } from '../../../../utility/hooks/react-query/useFlexTimeData';

import moment from 'moment';

const CalendarModal = ({
  setADayLeaveModal,
  aDayLeaveModal,
  setDateOnClick,
  dateOnClick,
  getUrlParamSearch,
  getValues,
}) => {
  const [dataModal, setDataModal] = useState([]);

  // react-query
  //#region react-query => search-data
  const onSuccessSearchData = (data) => {
    if (data.data && data.data.Status == true) {
      // console.log(data.data.ResultOnDb);
      setDataModal(
        data.data.ResultOnDb.filter((item) => {
          return item.display != 'background';
        })
      );
    } else {
      const message = {
        title: 'Search Events',
        message: data.data.Message,
      };

      ToastMessageWarning(message);
    }
  };

  const onErrorSearchData = (error) => {
    const message = {
      title: 'Search Events',
      message: error.Message,
    };
    ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchHrChecker(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(getValues(), dateOnClick, dateOnClick)
  );
  //#endregion react-query => search-data

  useEffect(() => {
    console.log(dataModal);
  }, [dataModal]);

  if (isLoading) {
    return;
  }

  return (
    <Modal
      isOpen={aDayLeaveModal}
      toggle={() => setADayLeaveModal(!aDayLeaveModal)}
      className='modal-dialog-centered'
    >
      <ModalHeader toggle={() => setADayLeaveModal(!aDayLeaveModal)}>
        Employee Leave On {moment(new Date(dateOnClick)).format('DD MMM YYYY')}
      </ModalHeader>
      <ModalBody>
        {isSuccess && dataModal.length > 0 ? (
          dataModal.map((item, index) => {
            return (
              <ol>
                <li>
                  {item.FLEX_TIME_REQUEST_EMPLOYEE_ID} {item.FLEX_TIME_DESCRIPTION}
                </li>
              </ol>
            );
          })
        ) : (
          <span>No data</span>
        )}
        <h5>{}</h5>
        <p>{}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color='secondary'
          onClick={() => {
            setADayLeaveModal(!aDayLeaveModal);
            setDateOnClick(null);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CalendarModal;
