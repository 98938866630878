// ** Icons Import
import { Home } from "react-feather";

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/",
    meta: {
      publicRoute: true,
    },
  },
];
