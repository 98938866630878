// ** React Imports
import React, { useEffect, useRef, useState } from 'react';

// ** Reactstrap Imports
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';

// ** Third Party Components
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { utils, writeFile } from 'xlsx';
// ** Utils
import { selectThemeColors } from '@utils';
import { getUserName } from '../../../auth/LoginUser';

// ** Custom Components
import ConfirmModal from '../../../components/common/ConfirmModal';
import { ToastMessageError, ToastMessageSuccess } from '../../../components/common/ToastMessage';

import { OptionComponent, timeLeaveArr } from '../../../components/common/react-select-custom';

import useFileDownloader from '../../../utility/hooks/react-query/useFileDownloader';

// ** React Select

// ** Custom hooks
import { useUpdateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId } from '../../../utility/hooks/react-query/useLeaveRemainDay';

// ** Styles
import '@styles/react/libs/flatpickr/flatpickr.scss';

import { useTranslation } from 'react-i18next';
import { NumberAndDecimalPattern } from '../../../components/common/ReactHookFormRegex';

const RemainLeaveEditModal = ({
  refTableData,
  refRowSelected,
  clearFilterFormSearch,
  isShowEditModel,
  setIsShowEditModel,
  getValuesFormSearch,
  setIsFetchData
}) => {
  // useState
  const [confirmModal, setConfirmModal] = useState(false);

  // useRef
  const refFocus = useRef(null);

  const { t } = useTranslation('Modal', { keyPrefix: 'Utility.Modal' });

  // react-hook-form
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue: setValueFormEdit,
    getValues,
    control,
    watch
  } = useForm();

  // Functions
  const handleEditUserLeave = () => {
    setConfirmModal(false);

    const dataItem = {
      LEAVE_REMAIN_DAY_EMPLOYEE_ID: refRowSelected.current?.EMPLOYEE_ID,
      LEAVE_TYPE_ID: refRowSelected.current?.LEAVE_TYPE_ID,
      LEAVE_REMAIN_DAY: getValues('leaveRemaining'),
      UPDATE_BY: getUserName()
    };

    updateUserLeave(dataItem);
  };

  const onSubmit = () => {
    setConfirmModal(true);
  };

  const onToggle = () => {
    if (isShowEditModel) {
      setIsShowEditModel(false);
    } else {
      setIsShowEditModel(true);
    }
  };

  // react-query
  const queryClient = useQueryClient();

  const onSuccessUpdateUserLeave = (data) => {
    if (data.data && data.data.Status == true) {
      const message = {
        message: data.data.Message,
        title: 'Edit User Leave'
      };

      ToastMessageSuccess(message);

      //clearFilterFormSearch();

      // refTableData.current.setSortBy([]);
      // refTableData.current.gotoPage(0);
      setIsFetchData(true);
      queryClient.invalidateQueries('REMAIN_LEAVE');
      setIsShowEditModel(false);
    } else {
      // refFocus.current.focus();

      const message = {
        title: t('Edit Remain Leave'),
        message: data.data.Message
      };

      ToastMessageError(message);
    }
  };

  const onErrorUpdateUserLeave = (error) => {
    const message = {
      title: t('Edit Remain Leave'),
      message: error.Message || error.message
    };

    ToastMessageError(message);
  };

  const { mutateAsync: updateUserLeave, isLoading: isLoadingUpdateUserLeave } =
    useUpdateLeaveRemainDayByLeaveReMainDayEmployeeIdAndLeaveTypeId(onSuccessUpdateUserLeave, onErrorUpdateUserLeave);
  //#endregion react-query => update-data

  // useEffect

  return (
    <>
      <Modal isOpen={isShowEditModel} backdrop='static' className='modal-sm'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={onToggle} tag='h4'>
            {t('Edit Remain Leave')}
          </ModalHeader>

          <ModalBody>
            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveType'>
                  {t('Employee Id')}
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  autoComplete='off'
                  disabled
                  value={refRowSelected.current?.EMPLOYEE_ID}
                />
              </Col>
            </Row>
            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveType'>
                  {t('Employee Name')}
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  autoComplete='off'
                  disabled
                  value={refRowSelected.current?.EMPLOYEE_FULL_NAME}
                />
              </Col>
            </Row>
            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveType'>
                  {t('Section')}
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  autoComplete='off'
                  disabled
                  value={refRowSelected.current?.EMPLOYEE_SECTION}
                />
              </Col>
            </Row>
            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveType'>
                  {t('Start Work')}
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  autoComplete='off'
                  disabled
                  value={refRowSelected.current?.EMPLOYEE_START_WORK}
                />
              </Col>
            </Row>
            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveType'>
                  {t('Leave Type')}
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  autoComplete='off'
                  disabled
                  value={refRowSelected.current?.LEAVE_TYPE_CODE}
                />
              </Col>
            </Row>

            <Row form>
              <Col sm='12' className='mb-1'>
                <Label className='form-label' for='leaveRemaining'>
                  {t('Remain Leave')}
                </Label>
                <Controller
                  defaultValue={refRowSelected.current?.LEAVE_REMAIN_DAY}
                  control={control}
                  name='leaveRemaining'
                  rules={{
                    required: t('Please enter Remain Leave'),
                    pattern: NumberAndDecimalPattern()
                  }}
                  render={({ field }) => (
                    <Input
                      className={classnames('form-control', {
                        'is-invalid': errors.leaveRemaining
                      })}
                      type='text'
                      placeholder=''
                      {...field}
                      autoComplete='off'
                    />
                  )}
                />
                {errors.leaveRemaining && <FormFeedback type='invalid'>{errors.leaveRemaining.message}</FormFeedback>}
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button
              type='submit'
              color='success'
              disabled={isLoadingUpdateUserLeave}
              outline={isLoadingUpdateUserLeave}
            >
              {isLoadingUpdateUserLeave ? (
                <>
                  <Spinner size='sm' />
                  <span className='ms-50'>{t('Saving...')}</span>
                </>
              ) : (
                <>{t('Save')}</>
              )}
            </Button>
            <Button type='button' color='secondary' onClick={onToggle} disabled={isLoadingUpdateUserLeave} outline>
              {t('Cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleEditUserLeave}
        onCloseClick={() => setConfirmModal(false)}
        isDelete={false}
      />
    </>
  );
};

export { RemainLeaveEditModal };
