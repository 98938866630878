import { useQuery, useMutation } from 'react-query';
import FlexTimeServices from '../../../services/FlexTimeServices';

// - Create
const create = (dataItem) => {
  const data = FlexTimeServices.createFlexTime(dataItem);
  return data;
};

const useCreate = (onSuccess, onError) => {
  return useMutation(create, {
    onSuccess,
    onError,
  });
};

// - Search
const search = (params) => {
  return FlexTimeServices.getAllByEmployeeId(params);
};

const useSearch = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_HISTORY', params], () => search(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

const searchFlexTimeBySpecificDate = (params) => {
  return FlexTimeServices.searchFlexTimeBySpecificDate(params);
};

const useSearchFlexTimeBySpecificDate = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_DATE', params], () => searchFlexTimeBySpecificDate(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: false,
  });
};

const searchHrChecker = (params) => {
  return FlexTimeServices.searchHrChecker(params);
};

const useSearchHrChecker = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_HR_CHECKER', params], () => searchHrChecker(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

const searchHrCheckerTableData = (params) => {
  return FlexTimeServices.searchHrCheckerTableData(params);
};

const useSearchHrCheckerTableData = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_HR_CHECKER_TABLE_DATA', params], () => searchHrCheckerTableData(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchFlexTimeHistory = (params) => {
  return FlexTimeServices.searchFlexTimeHistory(params);
};

const useSearchFlexTimeHistory = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_HISTORY', params], () => searchFlexTimeHistory(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchHrCheckerTableDataForExport = (params) => {
  return FlexTimeServices.searchHrCheckerTableDataForExport(params);
};

const useSearchHrCheckerTableDataForExport = (onSuccess, onError, params, isFetchData) => {
  return useQuery(
    ['FLEX_TIME_HR_CHECKER_TABLE_DATA_FOR_EXPORT', params],
    () => searchHrCheckerTableDataForExport(params),
    {
      onSuccess,
      onError,
      keepPreviousData: true,
      staleTime: Infinity,
      //staleTime: 0,
      cacheTime: 0,
      // enabled: isFetchData,
    }
  );
};

const searchForApprovalInFlow = (params) => {
  return FlexTimeServices.searchForApprovalInFlow(params);
};

const useSearchForApprovalInFlow = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['FLEX_TIME_APPROVAL', params], () => searchForApprovalInFlow(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Update
const update = (dataItem) => {
  const data = FlexTimeServices.update(dataItem);
  return data;
};

const useUpdate = (onSuccess, onError) => {
  return useMutation(update, {
    onSuccess,
    onError,
  });
};

// - Delete
const deleteData = (Property) => {
  const data = FlexTimeServices.delete(Property);
  return data;
};

const useDelete = (onSuccess, onError) => {
  return useMutation(deleteData, {
    onSuccess,
    onError,
  });
};

export {
  useCreate,
  useSearch,
  useUpdate,
  useDelete,
  useSearchHrChecker,
  useSearchForApprovalInFlow,
  useSearchHrCheckerTableData,
  useSearchHrCheckerTableDataForExport,
  useSearchFlexTimeHistory,
  useSearchFlexTimeBySpecificDate,
};
