import { IconArrowLeft, IconArrowRight, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';
import { handleMenuCollapsed } from '../../redux/layout';

const MenuCollapseButtonAction = () => {
  const dispatch = useDispatch();
  const layoutStore = useSelector((state) => state.layout);

  const menuCollapsed = layoutStore.menuCollapsed;

  const setMenuCollapsed = (val) => dispatch(handleMenuCollapsed(val));

  const toggleMenuCollapse = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <>
      {menuCollapsed ? (
        <IconChevronRight
          style={{ cursor: 'pointer' }}
          onClick={() => toggleMenuCollapse()}
        />
      ) : (
        <IconChevronLeft
          style={{ cursor: 'pointer' }}
          onClick={() => toggleMenuCollapse()}
        />
      )}
    </>
  );
};

export default MenuCollapseButtonAction;
