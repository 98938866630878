import mock from "../mock";

const data = {
  // blog list
  blogList: [
    {
      img: require("@src/assets/images/slider/02.jpg").default,
      title: "The Best Features Coming to iOS and Web design",
      id: 1,
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      blogPosted: "Jan 10, 2020",
      tags: ["Quote", "Fashion"],
      excerpt:
        "Donut fruitcake soufflé apple pie candy canes jujubes croissant chocolate bar ice cream.",
      comment: 76,
    },
    {
      img: require("@src/assets/images/slider/06.jpg").default,
      title: "Latest Quirky Opening Sentence or Paragraph",
      id: 2,
      avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
        .default,
      userFullName: "Jorge Griffin",
      blogPosted: "Jan 10, 2020",
      tags: ["Gaming", "Video"],
      excerpt:
        "Apple pie caramels lemon drops halvah liquorice carrot cake. Tiramisu brownie lemon drops.",
      comment: 2100,
    },
    {
      img: require("@src/assets/images/slider/04.jpg").default,
      title: "Share an Amazing and Shocking Fact or Statistic",
      id: 3,
      avatar: require("@src/assets/images/portrait/small/avatar-s-3.jpg")
        .default,
      userFullName: "Claudia Neal",
      blogPosted: "Jan 10, 2020",
      tags: ["Gaming", "Food"],
      excerpt:
        "Tiramisu jelly-o chupa chups tootsie roll donut wafer marshmallow cheesecake topping.",
      comment: 243,
    },
    {
      img: require("@src/assets/images/slider/03.jpg").default,
      title: "Withhold a Compelling Piece of Information",
      id: 4,
      avatar: require("@src/assets/images/portrait/small/avatar-s-14.jpg")
        .default,
      userFullName: "Fred Boone",
      blogPosted: "Jan 10, 2020",
      tags: ["Video"],
      excerpt:
        "Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.",
      comment: 10,
    },
    {
      img: require("@src/assets/images/slider/09.jpg").default,
      title: "Unadvertised Bonus Opening: Share a Quote",
      id: 5,
      avatar: require("@src/assets/images/portrait/small/avatar-s-13.jpg")
        .default,
      userFullName: "Billy French",
      blogPosted: "Jan 10, 2020",
      tags: ["Quote", "Fashion"],
      excerpt:
        "Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.",
      comment: 319,
    },
    {
      img: require("@src/assets/images/slider/10.jpg").default,
      title: "Ships at a distance have Every Man’s Wish on Board",
      id: 6,
      avatar: require("@src/assets/images/portrait/small/avatar-s-13.jpg")
        .default,
      userFullName: "Helena Hunt",
      blogPosted: "Jan 10, 2020",
      tags: ["Fashion", "Video"],
      excerpt:
        "A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.",
      comment: 1500,
    },
  ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        img: require("@src/assets/images/banner/14xx-1.png").default,
        title: "14xx-nm Pump Laser",
        name: "14xx-nm Pump Laser Diode Modules",
        category: "active",
        id: 7,
        createdTime: "Jan 14 2020",
      },
      {
        img: require("@src/assets/images/banner/Dual-Ju-ITLA.png").default,
        title: "Dual Ju ITLA",
        name: "Full Band Tunable",
        id: 8,
        createdTime: "Mar 04 2020",
        category: "active",
      },
      {
        img: require("@src/assets/images/banner/JU-uITLA.png").default,
        title: "Ju ITLA",
        name: "Full Band Tunable",
        id: 9,
        createdTime: "Feb 18 2020",
        category: "active",
      },

      {
        img: require("@src/assets/images/banner/1kw-fiber-laser.png").default,
        title: "1kW Fiber Laser Engine",
        name: "Fiber Laser",
        id: 10,
        createdTime: "Oct 08 2020",
        category: "fiber laser",
      },
      {
        img: require("@src/assets/images/banner/S124.png").default,
        title: "Ribbon Fiber Fusion Splicer S124",
        name: "Fusion Splice",
        id: 10,
        createdTime: "Oct 08 2020",
        category: "Fusion Splice",
      },
      {
        img: require("@src/assets/images/banner/S179.png").default,
        title: "Core Alignment Fusion Splicer S179A",
        name: "Fusion Splice",
        id: 10,
        createdTime: "Oct 08 2020",
        category: "Fusion Splice",
      },
    ],
    categories: [
      { category: "Fashion", icon: "Watch" },
      { category: "Food", icon: "ShoppingCart" },
      { category: "Gaming", icon: "Command" },
      { category: "Quote", icon: "Hash" },
      { category: "Video", icon: "Video" },
    ],
  },

  // detail
  blogDetail: {
    blog: {
      img: require("@src/assets/images/banner/fitel.jpg").default,
      title: "About Us",
      avatar: require("@src/assets/images/logo/fft_logo.png").default,
      userFullName: "Furukawa Fitel",
      createdTime: "Dec 07, 2020",
      tags: ["Network", "Telecommunications"],
      content:
        '<p class="card-text mb-2">Furukawa FITEL (Thailand) Co., Ltd. is a wholly owned subsidiary of Furukawa Electric Co., Ltd. in Japan and was established in Thailand in 2001 under BOI privilege with ISO 9001:2008 and ISO14001:2004 certificates.In advanced information-oriented society, optical communication system is a key technology for enabling people to access important information rapidly. Furukawa FITEL is firmly supporting an ideal base for communication by providing various products including active devices, passive components and connectors to be utilized from access FTTH network up to backbone metro and long-haul networks. Furukawa FITEL has been implementing cutting edge technologies continuously since its establishment, and has now outstanding manufacturing capabilities. Furukawa FITEL is one of the most important manufacturing facilities for Furukawa’s telecommunication business.</p><h4>Company Outline</h4><ul><li>Date of Establishment : February 13 , 2001</li><li>Ownership : 100% owned by Furukawa Electric Co.,Ltd</li><li>Land Area : 29,440 Squared meter</li><li>Head Office : 1/71 Moo 5 Rojana Industrial Park Phranakorn Sri Ayutthaya 13210 , Thailand</li></ul>',
      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2020",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },

  // edit
  blogEdit: {
    avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg").default,
    userFullName: "Chad Alexander",
    createdTime: "May 24, 2020",
    blogTitle: "The Best Features Coming to iOS and Web design",
    blogCategories: [
      { value: "fashion", label: "Fashion" },
      { value: "gaming", label: "Gaming" },
    ],
    slug: "the-best-features-coming-to-ios-and-web-design",
    status: "Published",
    excerpt:
      "<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>",
    featuredImage: require("@src/assets/images/slider/03.jpg").default,
  },
};

mock.onGet("/blog/list/data").reply(() => [200, data.blogList]);
mock.onGet("/blog/list/data/sidebar").reply(() => [200, data.blogSidebar]);
mock.onGet("/blog/list/data/detail").reply(() => [200, data.blogDetail]);
mock.onGet("/blog/list/data/edit").reply(() => [200, data.blogEdit]);
