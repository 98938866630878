import api_request from '../utility/api-request';
import FlexTimeAPI from '../api/FlexTimeAPI';

export default class FlexTimeServices {
  static getAllByEmployeeId(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/getAllByEmployeeId`,
      params: { data: params },
      method: 'GET',
    });
  }

  static getFlexTime() {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/getFlexTime`,
      method: 'GET',
    });
  }

  static createFlexTime(property) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/createFlexTime`,
      method: 'POST',
      data: property,
    });
  }

  static searchInFlow(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchFlexTimeBySpecificDate(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchFlexTimeBySpecificDate`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchRemainALInFlow(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchRemainALInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchHrChecker(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchHrChecker`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchHrCheckerTableData(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchHrCheckerTableData`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchFlexTimeHistory(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchFlexTimeHistory`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchHrCheckerTableDataForExport(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchHrCheckerTableDataForExport`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchForApprovalInFlow(params) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/searchForApprovalInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static update(property) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/update`,
      method: 'PATCH',
      data: property,
    });
  }

  static delete(property) {
    return api_request({
      url: `${FlexTimeAPI.API_ROOT_URL}/delete`,
      data: property,
      method: 'DELETE',
    });
  }
}
