// ** Service
import CommonServices from '../../services/CommonServices';

const ImageFromURL = (URL_PATH, setSmall, setLarge) =>
  CommonServices.getImageFromUrl({ URL_PATH })
    .then((responseJson) => {
      setSmall(URL.createObjectURL(responseJson.data));
      setLarge(URL.createObjectURL(responseJson.data));
    })
    .catch((error) => console.log(error));

const ImageFromUrlRawData = (URL_PATH, setImage, setValueFormEdit) =>
  CommonServices.getImageFromUrl({ URL_PATH })
    .then((responseJson) => {
      setImage(URL.createObjectURL(responseJson.data));
      setValueFormEdit('materialPropertyImageSrc', URL.createObjectURL(responseJson.data), {
        shouldDirty: true,
        shouldValidate: true,
      });
    })
    .catch((error) => console.log(error));

const setImageFromUrl = (URL_PATH, setImage) =>
  CommonServices.getImageFromUrl({ URL_PATH })
    .then((responseJson) => {
      setImage(URL.createObjectURL(responseJson.data));
    })
    .catch((error) => {});

export { ImageFromURL, ImageFromUrlRawData, setImageFromUrl };
