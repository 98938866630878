import { lazy } from 'react';
import RemainLeave from '../../views/leaveSystems/RemainLeave';

const LeaveRequest = lazy(() => import('../../views/leaveSystems/LeaveRequest/index'));

const LeaveHistory = lazy(() => import('../../views/leaveSystems/LeaveHistory/index'));

const LeaveRegularity = lazy(() => import('../../views/leaveSystems/LeaveRegularity/index'));

const LeaveRegularitySetting = lazy(() => import('../../views/leaveSystems/LeaveRegularitySetting/index'));

const LeaveTypeSetting = lazy(() => import('../../views/leaveSystems/LeaveTypeSetting/index'));

const UserLeaveEdit = lazy(() => import('../../views/leaveSystems/UserLeaveEdit/index'));

const UserProbationEdit = lazy(() => import('../../views/leaveSystems/UserProbationEdit/index'));

const Approval = lazy(() => import('../../views/leaveSystems/Approve/index'));

const RemainALDays = lazy(() => import('../../views/leaveSystems/RemainAL/index'));

const CheckSubordinateLeave = lazy(() => import('../../views/leaveSystems/CheckSubordinateLeave/index'));

const HrChecker = lazy(() => import('../../views/leaveSystems/HrChecker'));

const HrCheckerM75 = lazy(() => import('../../views/leaveSystems/HrCheckerM75'));

const HRForm = lazy(() => import('../../views/leaveSystems/HRForm'));

const HRForm75 = lazy(() => import('../../views/leaveSystems/HRForm75'));

const ExcelForm = lazy(() => import('../../views/leaveSystems/ImportExcelForm'));

const LeaveTypeRegulation = lazy(() => import('../../views/leaveSystems/LeaveTypeRegulation'));

const LeaveSystemRoutes = [
  {
    path: '/leave-request',
    element: <LeaveRequest />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-history',
    element: <LeaveHistory />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-regularity',
    element: <LeaveRegularity />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-regularity-setting',
    element: <LeaveRegularitySetting />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/approval',
    element: <Approval />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/remain-al',
    element: <RemainALDays />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/remain-leave',
    element: <RemainLeave />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/sub-leave',
    element: <CheckSubordinateLeave />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/hr-check',
    element: <HrChecker />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/hr-check-m75',
    element: <HrCheckerM75 />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-type-setting',
    element: <LeaveTypeSetting />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/user-leave-edit',
    element: <UserLeaveEdit />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/user-edit',
    element: <UserProbationEdit />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-request-form-hr',
    element: <HRForm />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/m-75-form-hr',
    element: <HRForm75 />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/excel-form',
    element: <ExcelForm />,
    //meta: {
    //   publicRoute: true,
    //},
  },
  {
    path: '/leave-type-regulation',
    element: <LeaveTypeRegulation />,
    //meta: {
    //   publicRoute: true,
    //},
  },
];

export default LeaveSystemRoutes;
