import { useMutation, useQuery } from 'react-query';
import LeaveService from '../../../services/LeaveServices';
// - Create
const create = (dataItem) => {
  const data = LeaveService.create(dataItem);
  return data;
};

const useCreate = (onSuccess, onError) => {
  return useMutation(create, {
    onSuccess,
    onError,
  });
};

const createHrChecker = (dataItem) => {
  const data = LeaveService.createHrChecker(dataItem);
  return data;
};

const useCreateHrChecker = (onSuccess, onError) => {
  return useMutation(createHrChecker, {
    onSuccess,
    onError,
  });
};

// - Search
const search = (params) => {
  return LeaveService.history(params);
};

const useSearch = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['LEAVE_HISTORY', params], () => search(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchEmployeeLeaveHistory = (params) => {
  return LeaveService.searchEmployeeLeaveHistory(params);
};

const useSearchEmployeeLeaveHistory = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['LEAVE_HISTORY_EMPLOYEE', params], () => searchEmployeeLeaveHistory(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    // enabled: isFetchData,
  });
};

// - Search
const searchInFlow = (params) => {
  return LeaveService.searchInFlow(params);
};

const useSearchInFlow = (onSuccess, onError, params, isFetchData) => {
  // console.log(params);
  return useQuery(['SUB_LEAVE', params], () => searchInFlow(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchRemainALInFlow = (params) => {
  return LeaveService.searchRemainALInFlow(params);
};

const useSearchRemainALInFlow = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['REMAIN_AL', params], () => searchRemainALInFlow(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchRemainLeave = (params) => {
  return LeaveService.searchRemainLeave(params);
};

const useSearchRemainLeave = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['REMAIN_LEAVE', params], () => searchRemainLeave(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchHrChecker = (params) => {
  return LeaveService.searchHrChecker(params);
};

const useSearchHrChecker = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['HR_CHECKER', params], () => searchHrChecker(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchCheckSubordinateCalendar = (params) => {
  return LeaveService.searchCheckSubordinateCalendar(params);
};

const useSearchCheckSubordinateCalendar = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['LEAVE_HISTORY_SUB_CALENDAR', params], () => searchCheckSubordinateCalendar(params), {
    onSuccess,
    onError,
    // keepPreviousData: true,
    staleTime: 0,
    //staleTime: 0,
    // cacheTime: Infinity,
    // enabled: isFetchData,
  });
};

const searchCheckSubordinateCalendarBySpecificDate = (params) => {
  return LeaveService.searchCheckSubordinateCalendarBySpecificDate(params);
};

const useSearchCheckSubordinateCalendarBySpecificDate = (onSuccess, onError, params, isFetchData) => {
  return useQuery(
    ['LEAVE_HISTORY_SUB_CALENDAR_BY_DATE', params],
    () => searchCheckSubordinateCalendarBySpecificDate(params),
    {
      onSuccess,
      onError,
      keepPreviousData: true,
      // staleTime: Infinity,
      staleTime: 0,
      // cacheTime: Infinity,
      // enabled: isFetchData,
    }
  );
};

const searchHrCheckerTableDataForExport = (params) => {
  return LeaveService.searchHrCheckerTableDataForExport(params);
};

const useSearchHrCheckerTableDataForExport = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['HR_CHECKER_FOR_EXPORT', params], () => searchHrCheckerTableDataForExport(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

const searchForApprovalInFlow = (params) => {
  return LeaveService.searchForApprovalInFlow(params);
};

const useSearchForApprovalInFlow = (onSuccess, onError, params, isFetchData) => {
  return useQuery(['APPROVAL', params], () => searchForApprovalInFlow(params), {
    onSuccess,
    onError,
    keepPreviousData: true,
    staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Get
const getDownload = (dataItem) => {
  const data = LeaveService.get(dataItem);
  return data;
};

const useGetDownload = (onSuccess, onError) => {
  return useMutation(getDownload, {
    onSuccess,
    onError,
  });
};

// - Update
const update = (dataItem) => {
  const data = LeaveService.update(dataItem);
  return data;
};

const useUpdate = (onSuccess, onError) => {
  return useMutation(update, {
    onSuccess,
    onError,
  });
};

// - Delete
const deleteData = (Property) => {
  const data = LeaveService.delete(Property);
  return data;
};

const useDelete = (onSuccess, onError) => {
  return useMutation(deleteData, {
    onSuccess,
    onError,
  });
};

const deleteLeave = (Property) => {
  const data = LeaveService.deleteLeave(Property);
  return data;
};

const useDeleteLeave = (onSuccess, onError) => {
  return useMutation(deleteLeave, {
    onSuccess,
    onError,
  });
};

export {
  useCreate,
  useCreateHrChecker,
  useDelete,
  useDeleteLeave,
  useGetDownload,
  useSearch,
  useSearchCheckSubordinateCalendar,
  useSearchEmployeeLeaveHistory,
  useSearchForApprovalInFlow,
  useSearchHrChecker,
  useSearchHrCheckerTableDataForExport,
  useSearchInFlow,
  useSearchRemainALInFlow,
  useSearchRemainLeave,
  useUpdate,
  useSearchCheckSubordinateCalendarBySpecificDate,
};
