import axios from "axios";

const client = (() => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_PERMISSION_END_POINT}`,
    // baseURL: `http://localhost:8000/`,
    // timeout: 5000,
    // headers: {'X-Custom-Header': 'bezkoder'}
  });
})();

const api_request = async function (options, store) {
  const onSuccess = function (response) {
    return response;
  };

  const onError = function (error) {
    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default api_request;
