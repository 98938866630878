// ** React Imports
import { Outlet } from 'react-router-dom';

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/HorizontalLayout';

// ** Menu Items Array
import navigation from '@src/navigation/horizontal';

import { useRef, useState, useEffect, useForm } from 'react';

import { getUserGroupName, getUserName } from '../auth/LoginUser';

import { useGetMenu } from '../utility/hooks/react-query/useSecurityCenterData';
import { ToastMessageError, ToastMessageSuccess, ToastMessageWarning } from '../components/common/ToastMessage';

// ** Function
const getUrlParamSearch = (userGroup) => {
  let params = ``;
  params += `"APPLICATION_NAME": "${process.env.REACT_APP_BASENAME}"`;
  params += `, "USER_NAME":"${getUserName()}"`;
  params += `, "USER_GROUP_NAME":"${userGroup || getUserGroupName()}"`;
  params = `{${params}}`;
  return params;
};

const HorizontalLayout = (props) => {
  const [menuData, setMenuData] = useState([]);
  const [isFetchData, setIsFetchData] = useState(true);
  const [userGroup, setUserGroup] = useState(null);
  const [menuLayout, setMenuLayout] = useState([]);
  const [menuPermission, setMenuPermission] = useState([]);

  const onSuccessSearchData = (data) => {
    setIsFetchData(false);

    if (data.data.Status == true && data.data.ResultOnDb.length > 0) {
      setMenuLayout(navigation);
      setMenuPermission(data.data.ResultOnDb);
    } else if (data.data.Status == true && data.data.ResultOnDb.length === 0) {
      setMenuLayout(navigation);
      setMenuPermission(data.data.ResultOnDb);

      const message = {
        title: 'Search GetMenu',
        message: 'Your user group do not have permission to see the another menu'
      };
      ToastMessageWarning(message);
    }
  };

  const onErrorSearchData = (error) => {
    setIsFetchData(false);

    setMenuLayout(navigation);
    setMenuPermission([]);

    const message = {
      title: 'Search GetMenu',
      message: error.message
    };
    ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useGetMenu(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(userGroup),
    isFetchData
  );

  if (isLoading) {
    return (
      <Layout menuData={[]} {...props}>
        <p className='card-text placeholder-glow'>
          <span className='placeholder col-7'></span>
        </p>{' '}
      </Layout>
    );
  }
  // Function Get Menu Intersection
  const getMenuIntersection = (Obj1, Obj2) => {
    return Obj1.filter((item1) => Obj2.some((item2) => item1.id == item2.id));
  };

  let menu = [];

  const GetMenu = (element) => {
    let elements = [];
    let menuInside = [];

    for (let idx = 0; idx < menuPermission.length; idx++) {
      const elementCompare = menuPermission[idx];

      if (elementCompare.id == element.id) {
        if (elementCompare.children && element.children) {
          elements = getMenuIntersection(element.children, elementCompare.children);
          element.children = elements;
          menuInside.push(element);
        } else {
          menu.push(element);
        }
      }
    }

    if (menuInside.length > 0) {
      menu.push(element);
    }
  };

  // Set default home menu
  for (let i = 0; i < 1; i++) {
    const element = menuLayout[i];
    menu.push(element);
  }

  // Menu application from Permission setting
  for (let i = 1; i < menuLayout.length; i++) {
    const element = menuLayout[i];

    if (element) {
      if (element.header === 'undefined') {
        continue;
      }
      GetMenu(element);
    }
  }

  return (
    <>
      <Layout
        menuData={menu}
        {...props}
        userGroup={userGroup}
        setUserGroup={setUserGroup}
        isFetchData={isFetchData}
        setIsFetchData={setIsFetchData}
      >
        <Outlet />
      </Layout>
    </>
  );
};

export default HorizontalLayout;
