import React from 'react';

// ** Third Party Components
import { useQueryClient } from 'react-query';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

// ** Reactstrap Imports
import { CardBody, Row, Col, Label, Input, Button, Spinner } from 'reactstrap';
import CardAction from '@components/card-actions';

import { useTranslation } from 'react-i18next';
import fetchSection from '../../../components/react-select/fetchSection';

// ** Utils
import { selectThemeColors } from '@utils';
import classNames from 'classnames';
import { fetchAllEmployee } from '../../../components/react-select/fetchEmployee';

const RemainLeaveSearch = ({
  refTableData,
  register,
  clearFilterFormSearch,
  controlFormSearch,
  isSearchDataForSearchForm,
  setIsSearchDataForSearchForm
}) => {
  // react-query
  const queryClient = useQueryClient();

  const { t } = useTranslation('Search Filter', {
    keyPrefix: 'Utility.Search Filter'
  });
  // Function
  const onClickSearch = () => {
    setIsSearchDataForSearchForm(true);
    queryClient.invalidateQueries('REMAIN_LEAVE');
    refTableData.current.setSortBy([]);
    refTableData.current.gotoPage(0);
  };

  const onClickClear = () => {
    clearFilterFormSearch();
  };

  return (
    <CardAction actions='collapse' title={t('Search filters')}>
      <CardBody className='pt-0'>
        <Row className='mt-0'>
          <Col className='mb-1' md='3' sm='12'>
            <Label className='form-label' for='employeeCode' style={{ fontSize: '16px' }}>
              {t('Employee Id')}
            </Label>
            <Controller
              // id="leaveType"
              control={controlFormSearch}
              name='employeeCode'
              render={({ field }) => (
                <AsyncSelect
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={(e) => {
                    return fetchAllEmployee({ EMPLOYEE_ID: e });
                  }}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value || ''}
                  getOptionLabel={(e) => e.EMPLOYEE_ID}
                  getOptionValue={(e) => e.EMPLOYEE_ID}
                  className='react-select'
                  classNamePrefix='select'
                  theme={selectThemeColors}
                  {...field}
                  placeholder={t('Enter employee id')}
                />
              )}
              // render={({ field: { ...fieldProps }, onChange }) => (
              //   <AsyncSelect
              //     isSearchable
              //     cacheOptions
              //     defaultOptions
              //     loadOptions={(e) => {
              //       return fetchAllEmployee({ EMPLOYEE_ID: e });
              //     }}
              //     // components={{
              //     //   Option: ({ data, ...props }) => (
              //     //     <components.Option {...props}>
              //     //       {data.EMPLOYEE_ID}
              //     //     </components.Option>
              //     //   ),
              //     // }}
              //     getOptionLabel={(e) => e.EMPLOYEE_ID}
              //     getOptionValue={(e) => e.EMPLOYEE_ID}
              //     classNamePrefix='select'
              //     theme={selectThemeColors}
              //     placeholder={t('Enter employee id')}
              //     {...fieldProps}
              //   />
              // )}
            />
          </Col>
          <Col className='mb-1' md='3' sm='12'>
            <Label className='form-label' for='employeeName' style={{ fontSize: '16px' }}>
              {t('Employee Name')}
            </Label>
            <Controller
              defaultValue=''
              control={controlFormSearch}
              id='employeeName'
              name='employeeName'
              render={({ field }) => <Input placeholder={t('Enter employee name')} {...field} autoComplete='off' />}
            />
          </Col>

          <Col className='mb-1' md='3' sm='12'>
            <Label className='form-label' for='employeeDept' style={{ fontSize: '16px' }}>
              {t('Section')}
            </Label>
            <Controller
              defaultValue=''
              control={controlFormSearch}
              name='employeeDept'
              render={({ field }) => (
                <AsyncSelect
                  isSearchable
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={(e) => fetchSection(e)}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                  getOptionLabel={(e) => e.SECTION}
                  getOptionValue={(e) => e.SECTION}
                  className='react-select'
                  classNamePrefix='select'
                  theme={selectThemeColors}
                  placeholder={t('Select Section')}
                  {...field}
                />
              )}
            />
          </Col>

          <div className='d-flex'>
            <Button
              className='me-1'
              color='primary'
              onClick={onClickSearch}
              disabled={isSearchDataForSearchForm}
              outline={isSearchDataForSearchForm}
            >
              {isSearchDataForSearchForm ? (
                <>
                  <Spinner size='sm' />
                  <span className='ms-50'>{t('Searching')}</span>
                </>
              ) : (
                <>{t('Search')}</>
              )}
            </Button>
            <Button outline color='secondary' onClick={onClickClear} disabled={isSearchDataForSearchForm}>
              {t('Clear')}
            </Button>
          </div>
        </Row>
      </CardBody>
    </CardAction>
  );
};

export default RemainLeaveSearch;
