import api_request from '../utility/api-request';
import api_request_download from '../utility/api-request-download';
import LeaveAPI from '../api/LeaveAPI';

export default class LeaveServices {
  static create(property) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/create`,
      method: 'POST',
      data: property,
    });
  }

  static createHrChecker(property) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/createHrChecker`,
      method: 'POST',
      data: property,
    });
  }

  static history(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/history`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchEmployeeLeaveHistory(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchNumberOfUsed`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchInFlow(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }
  static getLeaveTypeMaxDay(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/getLeaveTypeMaxDay`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchRemainALInFlow(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchRemainALInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchRemainLeave(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchRemainLeave`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchHrChecker(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchHrChecker`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchCheckSubordinateCalendar(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchCheckSubordinateCalendar`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchCheckSubordinateCalendarBySpecificDate(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchCheckSubordinateCalendarBySpecificDate`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchHrCheckerTableDataForExport(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchHrCheckerTableDataForExport`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchForApprovalInFlow(params) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/searchForApprovalInFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static get(property) {
    return api_request_download({
      url: `${LeaveAPI.API_ROOT_URL}/download`,
      params: { data: JSON.stringify(property) },
      method: 'GET',
    });
  }

  static update(property) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/update`,
      method: 'PATCH',
      data: property,
    });
  }

  static delete(property) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/delete`,
      data: property,
      method: 'DELETE',
    });
  }

  static deleteLeave(property) {
    return api_request({
      url: `${LeaveAPI.API_ROOT_URL}/deleteLeave`,
      data: property,
      method: 'DELETE',
    });
  }
}
