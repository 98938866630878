// ** Router imports
import { lazy } from "react";

// ** Router imports
import { useRoutes, Navigate, useNavigate } from "react-router-dom";

// ** Layouts
import BlankLayout from "@layouts/BlankLayout";

// ** Hooks Imports
import { useLayout } from "@hooks/useLayout";

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "../utility/Utils";

// ** GetRoutes
import { getRoutes } from "./routes";
import { useEffect } from "react";

// ** Components
const ComingSoon = lazy(() => import("../views/pages/misc/ComingSoonFlexTime"));
const Login = lazy(() =>
  import("../views/pages/authentication/LoginCover/LoginNext.js")
);

const NotAuthorized = lazy(() => import("../views/pages/misc/NotAuthorized"));

const Router = () => {
  // ** Hooks
  const { layout } = useLayout();
  const allRoutes = getRoutes(layout);
  const navigate = useNavigate();

  const getHomeRoute = () => {
    const user = getUserData();
    if (!user) {
      return "/login";
    } else {
      return getHomeRouteForLoggedInUser(user.role);
    }
  };

  useEffect(() => {
    if (getHomeRoute() == "/login") {
      return navigate("/login");
    }
  }, []);

  const routes = useRoutes([
    {
      path: "/",
      index: true,
      element: <Navigate replace to={getHomeRoute()} />,
    },
    {
      path: "/login",
      element: <BlankLayout />,
      children: [{ path: "/login", element: <Login /> }],
    },
    {
      path: "/auth/not-auth",
      element: <BlankLayout />,
      children: [{ path: "/auth/not-auth", element: <NotAuthorized /> }],
    },
    {
      path: "*",
      element: <BlankLayout />,
      children: [{ path: "*", element: <ComingSoon /> }],
    },
    ...allRoutes,
  ]);

  return routes;
};

export default Router;
