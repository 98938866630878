// ** React Imports
import { Fragment, useState, useEffect } from "react";

// ** Third Party Components
import classnames from "classnames";
import { Row, Col } from "reactstrap";

// ** Calendar App Component Imports
import Calendar from "./Calendar";
import SidebarLeft from "./SidebarLeft";

// ** Custom Hooks
import { useRTL } from "@hooks/useRTL";

// ** Styles
import "@styles/react/apps/app-calendar.scss";

// ** Third Party Libraries
import { useForm } from "react-hook-form";

// ** CalendarColors
const calendarsColor = {
  "Company Holiday": "danger",
  "Substitution Holiday": "danger",
  "Traditional Holiday": "danger",
  "07.30-16.30": "primary",
  "08.30-17.30": "success",
  "09.30-18.30": "info",
};

const CalendarComponent = () => {
  const [calendarApi, setCalendarApi] = useState(null);
  const [infoEmployee, setInfoEmployee] = useState(undefined);
  const [isFetchData, setIsFetchData] = useState(true);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [isSearchDataForSearchForm, setIsSearchDataForSearchForm] =
    useState(false);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setTimeout(() => {
        setLeftSidebarOpen(true);
      }, 1000);
    }
  }, []);

  // ** Hooks
  const [isRtl] = useRTL();

  // React Hook Form
  // - Search
  const {
    register,
    getValues,
    reset: resetAllFormSearch,
    control: controlFormSearch,
    formState: errors,
  } = useForm();

  // ** LeftSidebar Toggle Function
  const toggleSidebar = (val) => {
    setLeftSidebarOpen(val);
  };

  // Functions
  const clearFilterFormSearch = () => {
    resetAllFormSearch({
      employeeCode: "",
      employeeName: "",
    });
  };

  return (
    <Fragment>
      <div className="app-calendar overflow-hidden border">
        <Row className="g-0">
          <Col
            id="app-calendar-sidebar"
            className={classnames(
              "col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",
              {
                show: leftSidebarOpen,
              }
            )}
          >
            <SidebarLeft
              register={register}
              toggleSidebar={toggleSidebar}
              clearFilterFormSearch={clearFilterFormSearch}
              controlFormSearch={controlFormSearch}
              isSearchDataForSearchForm={isSearchDataForSearchForm}
              setIsSearchDataForSearchForm={setIsSearchDataForSearchForm}
              errors={errors}
              infoEmployee={infoEmployee}
            />
          </Col>
          <Col className="position-relative">
            <Calendar
              isRtl={isRtl}
              calendarApi={calendarApi}
              calendarsColor={calendarsColor}
              setCalendarApi={setCalendarApi}
              toggleSidebar={toggleSidebar}
              setIsFetchData={setIsFetchData}
              isFetchData={isFetchData}
              getValues={getValues}
              clearFilterFormSearch={clearFilterFormSearch}
              setIsSearchDataForSearchForm={setIsSearchDataForSearchForm}
              setInfoEmployee={setInfoEmployee}
            />
          </Col>
          <div
            className={classnames("body-content-overlay", {
              show: leftSidebarOpen === true,
            })}
            onClick={() => toggleSidebar(false)}
          ></div>
        </Row>
      </div>
    </Fragment>
  );
};

export default CalendarComponent;
