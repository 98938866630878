import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const HomeDetails = lazy(() => import('../../views/home/details/index'));

const PagesRoutes = [
  {
    path: '/home/detail/:id',
    element: <HomeDetails />
  },
  {
    path: '/home/detail',
    element: <Navigate to="/home/detail/1" />
  }
];

export default PagesRoutes;
