import api_request from "../utility/api-request";
import CommonAPI from "../api/CommonAPI";

export default class CommonServices {
  static create(CommonProperty) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/create`,
      method: "POST",
      data: CommonProperty
    });
  }

  static search(params) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/search`,
      params: { data: params },
      method: "GET"
    });
  }

  static get(CommonProperty) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/get`,
      params: { data: JSON.stringify(CommonProperty) },
      method: "GET"
    });
  }

  static update(CommonProperty) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/update`,
      method: "PATCH",
      data: CommonProperty
    });
  }

  static delete(CommonProperty) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/delete`,
      data: CommonProperty,
      method: "DELETE"
    });
  }

  static getYearNow() {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/getYearNow`,
      method: "GET"
    });
  }

  static getImageFromUrl(URL) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/getImageFromUrl`,
      data: URL,
      method: "POST",
      responseType: "blob"
    });
  }

  static getExcelFromUrl(URL) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/getExcelFromUrl`,
      method: "GET",
      responseType: "blob"
    });
  }

  static getByLikeMonthShortNameEnglish(CommonProperty) {
    return api_request({
      url: `${CommonAPI.API_ROOT_URL}/getByLikeMonthShortNameEnglish`,
      params: { data: JSON.stringify(CommonProperty) },
      method: "GET"
    });
  }
}
