// ** Service
import EmployeeService from "../../services/EmployeeService";

const fetchEmployeeCodeByEmployeeCodeAndInuse = (
  employeeCode = "",
  inuse = ""
) =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
      INUSE: `${inuse}`,
    };
    EmployeeService.getByLikeEmployeeCodeAndInuse(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchEmployeeCodeByEmployeeCodeAndInFlowAndInuse = (
  employee_requested,
  employeeCode = "",
  inuse = ""
) =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_ID_REQUEST: employee_requested,
      EMPLOYEE_CODE: `${employeeCode}`,
      INUSE: `${inuse}`,
    };
    EmployeeService.getByLikeEmployeeCodeAndInFlowAndInuse(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

export {
  fetchEmployeeCodeByEmployeeCodeAndInuse,
  fetchEmployeeCodeByEmployeeCodeAndInFlowAndInuse,
};
