import React from "react";

// ** Third Party Components
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useQueryClient } from "react-query";
import classnames from "classnames";

// ** Utils
import { selectThemeColors } from "@utils";

import { fetchEmployeeCodeByEmployeeCodeAndInFlowAndInuse } from "../../../../components/react-select/fetchEmployeeCode";
import { getUserName } from "../../../../auth/LoginUser";

// ** Reactstrap Imports
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const SidebarLeft = ({
  register,
  clearFilterFormSearch,
  controlFormSearch,
  isSearchDataForSearchForm,
  setIsSearchDataForSearchForm,
  errors,
  infoEmployee,
}) => {
  // react-query
  const queryClient = useQueryClient();

  const { t } = useTranslation("Calendar", { keyPrefix: "Utility.Calendar" });

  // Function
  const onClickSearch = () => {
    setIsSearchDataForSearchForm(true);
    queryClient.invalidateQueries("FLEX_TIME_HR_CHECKER");
  };

  const onClickClear = () => {
    clearFilterFormSearch();
  };

  return (
    <>
      <Card className="card-custom">
        <CardHeader>
          <h3>{t("Search filter")}</h3>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="mt-0">
            <Col className="mb-1" md="12">
              <Label className="form-label" for="employeeCode">
                {t("Employee Id")}
              </Label>
              <Controller
                defaultValue=""
                control={controlFormSearch}
                id="employeeCode"
                name="employeeCode"
                rules={{ required: "Employee ID is required" }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                    isSearchable
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={(data) =>
                      fetchEmployeeCodeByEmployeeCodeAndInFlowAndInuse(
                        getUserName(),
                        data,
                        "1"
                      )
                    }
                    getOptionLabel={(e) => e.EMPLOYEE_ID}
                    getOptionValue={(e) => e.EMPLOYEE_ID}
                    className={classnames("react-select", {
                      "is-invalid": errors.employeeCode,
                    })}
                    classNamePrefix="select"
                    theme={selectThemeColors}
                    placeholder={t("Select employee id")}
                  />
                )}
              />
              {errors.employeeCode && (
                <FormFeedback type="invalid">
                  {errors.employeeCode.message}
                </FormFeedback>
              )}
            </Col>
          </Row>
          {/* <Row className="mt-0">
          <Col className="mb-1" md="12">
            <Label className="form-label" for="employeeName">
              Employee Name
            </Label>
            <Controller
              defaultValue=""
              control={controlFormSearch}
              id="employeeName"
              name="employeeName"
              render={({ field }) => (
                <Input
                  placeholder="Enter Employee Name"
                  {...field}
                  autoComplete="off"
                />
              )}
            />
          </Col>
        </Row> */}
          <CardFooter>
            <div className="d-flex">
              <Button
                className="me-1"
                color="primary"
                onClick={onClickSearch}
                disabled={isSearchDataForSearchForm}
                outline={isSearchDataForSearchForm}
              >
                {isSearchDataForSearchForm ? (
                  <>
                    <Spinner size="sm" />
                    <span className="ms-50">{t("Searching")}</span>
                  </>
                ) : (
                  <>{t("Search")}</>
                )}
              </Button>
              <Button
                outline
                color="secondary"
                onClick={onClickClear}
                disabled={isSearchDataForSearchForm}
              >
                {t("Clear")}
              </Button>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
      {infoEmployee ? (
        <Card className="card-custom">
          <CardHeader>
            <h4>{t("Employee Information")}</h4>
          </CardHeader>

          <ListGroup flush>
            <ListGroupItem>
              {t("ID")} : {infoEmployee.EMPLOYEE_ID}
            </ListGroupItem>
            <ListGroupItem>
              {t("Name")} :{" "}
              {`${infoEmployee.EMPLOYEE_NAME} ${infoEmployee.EMPLOYEE_SURNAME}`}
            </ListGroupItem>
            <ListGroupItem>
              {t("Department")} :{" "}
              {infoEmployee.EMPLOYEE_DEPT?.toLowerCase()
                .charAt(0)
                .toUpperCase() +
                infoEmployee.EMPLOYEE_DEPT?.slice(1).toLowerCase()}
            </ListGroupItem>
            <ListGroupItem>
              {t("Section")} :{" "}
              {infoEmployee.EMPLOYEE_SECTION?.toLowerCase()
                .charAt(0)
                .toUpperCase() +
                infoEmployee.EMPLOYEE_SECTION?.slice(1).toLowerCase()}
            </ListGroupItem>
          </ListGroup>
        </Card>
      ) : null}
    </>
  );
};

export default SidebarLeft;
