import api_request from '../utility/api-request';
import EmployeeAPI from '../api/EmployeeAPI';

export default class EmployeeService {
  static create(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/create`,
      method: 'POST',
      data: Property,
    });
  }

  static createPassPro(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/setPassPro`,
      method: 'POST',
      data: Property,
    });
  }

  static search(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/search`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchUserProbation(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchUserProbation`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchEmployeeIdByFlow(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchEmployeeIdByFlow`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchEmployeeByEmployeeCode(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchEmployeeByEmployeeCode`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchApprover(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchApprover`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchFlexTimeApprover(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchFlexTimeApprover`,
      params: { data: params },
      method: 'GET',
    });
  }

  static searchTimeRecordApprover(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/searchTimeRecordApprover`,
      params: { data: params },
      method: 'GET',
    });
  }

  static get(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getAll`,
      params: { data: JSON.stringify(Property) },
      method: 'GET',
    });
  }

  static getAllEmployee(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getAllEmployee`,
      params: { data: JSON.stringify(Property) },
      method: 'GET',
    });
  }

  static getDepartment(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getDepartment`,
      params: { data: JSON.stringify(Property) },
      method: 'GET',
    });
  }

  static getSection(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getSection`,
      params: { data: JSON.stringify(Property) },
      method: 'GET',
    });
  }

  static update(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/update`,
      method: 'PATCH',
      data: Property,
    });
  }

  static updateUserProbation(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/setPassPro`,
      method: 'PATCH',
      data: Property,
    });
  }

  static delete(Property) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/delete`,
      data: Property,
      method: 'DELETE',
    });
  }

  static getByLikeEmployeeCodeAndInuse(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getByLikeEmployeeCodeAndInuse`,
      params: { data: params },
      method: 'GET',
    });
  }

  static getByLikeEmployeeCodeAndInFlowAndInuse(params) {
    return api_request({
      url: `${EmployeeAPI.API_ROOT_URL}/getByLikeEmployeeCodeAndInFlowAndInuse`,
      params: { data: params },
      method: 'GET',
    });
  }
}
