// ** React Imports
import React, { Fragment, useEffect, useState, useRef } from 'react';

// ** Full Calendar & it's Plugins
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// ** Third Party Components
import { Menu } from 'react-feather';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, CardText } from 'reactstrap';
import moment from 'moment';

// ** Custom Components
import { ToastMessageError, ToastMessageWarning } from '../../../../components/common/ToastMessage';
import UILoader from '@components/ui-loader';
import Spinner from '@components/spinner/Loading-spinner';

// ** Custom Hook
import { useSearchHrChecker } from '../../../../utility/hooks/react-query/useFlexTimeData';
import { useSearchEmployeeByEmployeeCode } from '../../../../utility/hooks/react-query/useEmployeeData';
import { getUserName } from '../../../../auth/LoginUser';
import { useQueryClient } from 'react-query';
import CalendarModal from './CalendarModal';

// ** Icon

// ** Function
const getUrlParamSearch = ({ employeeCode = '' }, startDate, endDate) => {
  let params = ``;
  params += `"EMPLOYEE_CODE":"${employeeCode?.EMPLOYEE_ID || ''}"`;
  params += `, "START_DATE":"${startDate || ''}"`;
  params += `, "END_DATE":"${endDate || ''}"`;
  params += `, "EMPLOYEE_ID_REQUEST":"${getUserName()}"`;

  params = `{${params}}`;
  return params;
};

const Calendar = ({
  isRtl,
  calendarApi,
  calendarsColor,
  setCalendarApi,
  setIsFetchData,
  isFetchData,
  getValues,
  clearFilterFormSearch,
  setIsSearchDataForSearchForm,
  setInfoEmployee,
  toggleSidebar,
}) => {
  // react-query
  const queryClient = useQueryClient();

  const [basicModal, setBasicModal] = useState(false);
  const [dateOnClick, setDateOnClick] = useState('');
  const [aDayLeaveModal, setADayLeaveModal] = useState(false);
  const [eventDetail, setEventDetail] = useState('');
  const [eventDate, setEventDate] = useState('');

  // ** Refs
  const calendarRef = useRef(null);

  // ** Props

  // ** UseEffect checks for CalendarAPI Update
  useEffect(() => {
    if (calendarApi === null) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarApi]);

  // react-query
  //#region react-query => search-data
  const onSuccessSearchData = (data) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);

    if (data.data && data.data.Status == true) {
    } else {
      const message = {
        title: 'Search Events',
        message: data.data.Message,
      };

      ToastMessageWarning(message);
    }
  };

  const onErrorSearchData = (error) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);

    const message = {
      title: 'Search Events',
      message: error.Message,
    };
    ToastMessageError(message);
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchHrChecker(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(getValues(), calendarApi?.view.activeStart, calendarApi?.view.activeEnd),
    isFetchData
  );
  //#endregion react-query => search-data

  // react-query
  //#region react-query => search-data
  const onSuccessSearchEmployee = (data) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);

    if (data.data && data.data.Status == true) {
      setInfoEmployee(data.data.ResultOnDb[0]);
    } else {
      setInfoEmployee({});
      const message = {
        title: 'Search Events',
        message: data.data.Message,
      };

      ToastMessageWarning(message);
    }
  };

  const onErrorSearchEmployee = (error) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);
    setInfoEmployee({});

    const message = {
      title: 'Search Events',
      message: error.Message,
    };
    ToastMessageError(message);
  };

  const { isLoadingEmployee, errorEmployee, dataEmployee, isSuccessEmployee, isFetchingEmployee } =
    useSearchEmployeeByEmployeeCode(
      onSuccessSearchEmployee,
      onErrorSearchEmployee,
      getUrlParamSearch(getValues()),
      isFetchData
    );
  //#endregion react-query => search-data

  const calendarOptions = {
    events: data?.data?.ResultOnDb,
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    datesSet(info) {
      queryClient.invalidateQueries('FLEX_TIME_HR_CHECKER');
    },
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'today',
    },

    displayEventTime: false,
    eventDisplay: 'block',

    // hiddenDays: [0],

    /*
        Enable dragging and resizing event
        ? Docs: https://fullcalendar.io/docs/editable
      */
    editable: false,

    /*
        Enable resizing event from start
        ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
      */
    eventResizableFromStart: false,

    /*
        Automatically scroll the scroll-containers during event drag-and-drop and date selecting
        ? Docs: https://fullcalendar.io/docs/dragScroll
      */
    dragScroll: false,

    /*
        Max number of events within a given day
        ? Docs: https://fullcalendar.io/docs/dayMaxEvents
      */
    dayMaxEvents: 2,

    /*
        Determines if day names and week names are clickable
        ? Docs: https://fullcalendar.io/docs/navLinks
      */
    navLinks: false,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.title];

      return [
        // Background Color
        `bg-light-${colorName}`,
      ];
    },

    eventClick({ event: clickedEvent }) {
      setEventDate(moment(clickedEvent._instance.range.start).format('DD-MMM-YYYY'));
      setEventDetail(clickedEvent._def.title);

      // setBasicModal(!basicModal);

      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      // isAddNewEventSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        text: <Menu className='d-xl-none d-block' />,
        click() {
          toggleSidebar(true);
        },
      },
    },

    dateClick(info) {
      setADayLeaveModal(true);
      setDateOnClick(info.date);
    },

    /*
        Handle event drop (Also include dragged event)
        ? Docs: https://fullcalendar.io/docs/eventDrop
        ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
      */
    // eventDrop({ event: droppedEvent }) {
    //   dispatch(updateEvent(droppedEvent));
    //   toast.success("Event Updated");
    // },

    /*
        Handle event resize
        ? Docs: https://fullcalendar.io/docs/eventResize
      */
    // eventResize({ event: resizedEvent }) {
    //   dispatch(updateEvent(resizedEvent));
    //   toast.success("Event Updated");
    // },

    ref: calendarRef,

    // Get direction from app state (store)
    direction: isRtl ? 'rtl' : 'ltr',
  };

  const Loader = () => {
    return (
      <Fragment>
        <Spinner />
        <CardText>Loading data...</CardText>
      </Fragment>
    );
  };

  return (
    <>
      <UILoader
        blocking={isFetching || isLoading}
        loader={<Loader />}
      >
        <div className='basic-modal'>
          <CalendarModal
            setADayLeaveModal={setADayLeaveModal}
            aDayLeaveModal={aDayLeaveModal}
            setDateOnClick={setDateOnClick}
            dateOnClick={dateOnClick}
            getUrlParamSearch={getUrlParamSearch}
            getValues={getValues}
          />
          {/* <Modal
            isOpen={basicModal}
            toggle={() => setBasicModal(!basicModal)}
            className='modal-dialog-centered'
          >
            <ModalHeader toggle={() => setBasicModal(!basicModal)}>Flex Time Detail</ModalHeader>
            <ModalBody>
              <h5>Detail on {eventDate}</h5>
              <p>{eventDetail}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color='primary'
                onClick={() => setBasicModal(!basicModal)}
              >
                Accept
              </Button>
            </ModalFooter>
          </Modal> */}
        </div>

        <Card className='shadow-none border-0 mb-0 rounded-0'>
          <CardBody className='pb-0'>
            <FullCalendar {...calendarOptions} />{' '}
          </CardBody>
        </Card>
      </UILoader>
    </>
  );
};

export default Calendar;
