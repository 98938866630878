import { IconAlignCenter, IconArrowAutofitWidth } from '@tabler/icons-react';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';
import { handleContentWidth, handleMenuHidden } from '@store/layout';

const CustomizerNavbar = () => {
  const dispatch = useDispatch();
  const layoutStore = useSelector((state) => state.layout);

  const contentWidth = layoutStore.contentWidth;

  const setContentWidth = (val) => dispatch(handleContentWidth(val));

  const toggleContentBoxedWidth = (val) => {
    setContentWidth(val);
  };

  const toggleContentFullWidth = (val) => {
    setContentWidth(val);
  };

  return (
    <>
      {contentWidth === 'boxed' ? (
        <IconArrowAutofitWidth
          style={{ cursor: 'pointer' }}
          onClick={() => toggleContentFullWidth('full')}
        />
      ) : (
        <IconAlignCenter
          style={{ cursor: 'pointer' }}
          onClick={() => toggleContentBoxedWidth('boxed')}
        />
      )}
    </>
  );
};

export default CustomizerNavbar;
