// ** Service
//import { getUserName } from "../../auth/LoginUser";
import { getUserGroupName, getUserName } from "../../auth/LoginUser";
import SecurityCenterServices from "../../services/SecurityCenterServices";

// ** useRef
const fetchUserGroup = (userGroupName) =>
  new Promise((resolve) => {
    const param = { USER_NAME: getUserName() , USER_GROUP_NAME :userGroupName  };
    SecurityCenterServices.getUserGroupByUsernameAndLikeUserGroupName(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

export { fetchUserGroup };