// ** React Imports
import { Fragment, useState } from "react";

// ** Components
import CalendarComponent from "./Calendar";

// ** Custom Components
import Breadcrumbs from "@components/breadcrumbs";

// ** Styles
import "@styles/react/apps/app-ecommerce.scss";

const FlexTimeCheckSubordinate = () => {
  // ** States
  const [activeView, setActiveView] = useState("calendar");

  return (
    <Fragment>
      <CalendarComponent />
      {/* <Header setActiveView={setActiveView} activeView={activeView} /> */}

      {/* {activeView === "calendar" ? <CalendarComponent /> : <TableData />} */}
    </Fragment>
  );
};

export default FlexTimeCheckSubordinate;
