import { useQuery, useMutation } from "react-query";
import SecurityCenterServices from "../../../services/SecurityCenterServices";
// - Create
const create = (dataItem) => {
  const data = SecurityCenterServices.create(dataItem);
  return data;
};

const useCreate = (onSuccess, onError) => {
  return useMutation(create, {
    onSuccess,
    onError,
  });
};

// - Search
const search = (dataItem) => {
  return SecurityCenterServices.search(dataItem);
};

const useSearch = (onSuccess, onError, dataItem, isFetchData) => {
  return useQuery("SecurityCenter", () => search(dataItem), {
    onSuccess,
    onError,
    // keepPreviousData: true,
    // staleTime: Infinity,
    //staleTime: 0,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - Get
const get = (SecurityCenterProperty) => {
  return SecurityCenterServices.get(SecurityCenterProperty);
};

const useGet = (onSuccess, onError, SecurityCenterProperty) => {
  return useQuery(
    ["USER_GROUP_ID", SecurityCenterProperty.SecurityCenter_ID],
    () => get(SecurityCenterProperty),
    {
      onSuccess,
      onError,
      // cacheTime: 0,
      enabled: SecurityCenterProperty.SecurityCenter_ID ? true : false,
    }
  );
};

// - Update
const update = (dataItem) => {
  const data = SecurityCenterServices.update(dataItem);
  return data;
};

const useUpdate = (onSuccess, onError) => {
  return useMutation(update, {
    onSuccess,
    onError,
  });
};

// - Delete
const deleteData = (SecurityCenterProperty) => {
  const data = SecurityCenterServices.delete(SecurityCenterProperty);
  return data;
};

const useDelete = (onSuccess, onError) => {
  return useMutation(deleteData, {
    onSuccess,
    onError,
  });
};

// - getUserByToken
const getUserByToken = (dataItem) => {
  return SecurityCenterServices.getUserByToken(dataItem);
};

const useGetUserByToken = (dataItem) => {
  return useQuery("SecurityCenter", () => getUserByToken(dataItem), {
    cacheTime: 0,
  });
};

// - getMenu
const getMenu = (dataItem) => {
  return SecurityCenterServices.getMenu(dataItem);
};

const useGetMenu = (onSuccess, onError, dataItem, isFetchData) => {
  return useQuery("LeaveSystemMenu", () => getMenu(dataItem), {
    onSuccess,
    onError,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

// - getUserGroup
const getUserGroup = (dataItem) => {
  return SecurityCenterServices.getUserGroup(dataItem);
};

const useGetUserGroup = (onSuccess, onError, dataItem, isFetchData) => {
  return useQuery("SecurityCenter", () => getUserGroup(dataItem), {
    onSuccess,
    onError,
    cacheTime: 0,
  });
};

export {
  useCreate,
  useSearch,
  useGet,
  useUpdate,
  useDelete,
  useGetMenu,
  useGetUserGroup,
  useGetUserByToken,
};
