import imgEmpty from '@src/assets/images/custom/empty-nodata-state.svg'
import { Row } from 'reactstrap'

const NoResultsFound = () => {
  return (
    <>
      <Row className="justify-content-center align-items-center">
        <img style={{ width: 100 }} src={imgEmpty} />
      </Row>
      <Row className="justify-content-center align-items-center mt-1 mb-1">
        No results found{' '}
        <small
          className="text-center text-secondary"
          style={{ marginTop: '3px' }}
        >
          Please adjust your filters or Try again.
        </small>
      </Row>
    </>
  )
}

export default NoResultsFound
