import axios from 'axios';

const client = (() => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_END_POINT}`,
    // timeout: 5000,
    // headers: {'X-Custom-Header': 'bezkoder'}
    // responseType: "blob",
    // onDownloadProgress: (progressEvent) => {
    // console.log(progressEvent);
    // console.log((progressEvent.loaded / progressEvent.total) * 100 + " %");
    // console.log(progressEvent.total);
    // },
  });
})();

const api_request = async function (options, store) {
  const onSuccess = function (response) {
    return response;
  };

  const onError = function (error) {
    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default api_request;
