// ** Icons Import
import { Heart } from 'react-feather';

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        © {2022}{' '}
        <a href="http://www.fitel.co.th/" target="_blank" rel="noopener noreferrer">
          Smart FFT
        </a>
        <span className="d-none d-sm-inline-block">, All rights Reserved.</span>
      </span>
      <span className="float-md-end d-none d-md-block">
        Design & Develop by DX section
        <Heart size={14} />
      </span>
    </p>
  );
};

export default Footer;
