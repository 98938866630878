// ** Service
import EmployeeService from "../../services/EmployeeService";

const fetchAllEmployee = (dataItem) =>
  new Promise((resolve) => {
    EmployeeService.getAllEmployee(dataItem)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

export { fetchAllEmployee };
