// ** Icons Import
import { Children } from 'react';
import { Home, Circle } from 'react-feather';

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: '/home/detail/1'
  }
];
