// ** React Imports
import React, { Fragment, useEffect, useMemo, useReducer } from 'react';

// ** Custom Components
import Spinner from '@components/spinner/Loading-spinner';
import UILoader from '@components/ui-loader';
import { GenerateSortingIndicator, NormalColumn } from '../../../components/common/TableColumn';

import { ToastMessageError } from '../../../components/common/ToastMessage';

// ** Reactstrap Imports
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';

// ** Third Party Components
import { usePagination, useSortBy, useTable } from 'react-table';

// ** Custom Hook
import { useSearchRemainLeave } from '../../../utility/hooks/react-query/useLeaveData';

// ** Components
// import {
//     ApplicationAddModal,
//     ApplicationDeleteModal,
//     ApplicationEditModal,
//   } from "./ApplicationModal";

import NoResultsFound from '../../../components/common/NoResultsFound';

import { Check, Download, Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { getUserName } from '../../../auth/LoginUser';
import { ExportRemainLeaveModal, RemainLeaveEditModal } from './RemainLeaveModal';
import { useState } from 'react';
import useFileDownloader from '../../../utility/hooks/react-query/useFileDownloader';

// ** Function
const getUrlParamSearch = (
  queryPageIndex,
  queryPageSize,
  querySortBy,
  { employeeName = '', employeeCode = '', employeeDept = '' }
) => {
  let params = ``;

  params += `"EMPLOYEE_NAME":"${employeeName}"`;
  params += `, "EMPLOYEE_CODE":"${employeeCode?.EMPLOYEE_ID || ''}"`;
  params += `, "EMPLOYEE_SECTION":"${employeeDept?.SECTION || ''}"`;
  params += `, "EMPLOYEE_ID_REQUEST":"${getUserName()}"`;
  params += `, "Start":"${queryPageIndex}"`;
  params += `, "Limit":"${queryPageSize}"`;
  if (querySortBy?.length > 0) {
    params += `, "Order":${JSON.stringify(querySortBy).replace('MODIFIED_DATE', 'UPDATE_DATE')}`;
  }
  params = `{${params}}`;

  return params;
};

// ** Initial Data
const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
  querySortBy: []
};

// ** Redux
const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload
      };
    case PAGE_SORT_CHANGED:
      return {
        ...state,
        querySortBy: payload
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const RemainLeaveTableData = ({
  refTableData,
  isFetchData,
  setIsFetchData,
  getValues,
  refRowSelected,
  clearFilterFormSearch,
  setIsSearchDataForSearchForm
}) => {
  const { t } = useTranslation('Table Data', {
    keyPrefix: 'Utility.Table Data'
  });

  // useState

  const [isShowEditModel, setIsShowEditModel] = useState(false);
  const [isShowExportRemainLeaveModal, setIsShowExportRemainLeaveModal] = useState(false);
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);

  const onClickEdit = (rowValue) => {
    refRowSelected.current = rowValue;
    setIsShowEditModel(true);
  };

  const generateSortingIndicator = (column) => {
    return <GenerateSortingIndicator column={column} />;
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  // columns
  const columns = useMemo(
    () => [
      {
        Header: t('Actions'),
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Edit
                className='text-body cursor-pointer me-50'
                size={15}
                id={`item-price-green-product-tooltip-${cellProps.row.original.SCT_ID}`}
                onClick={(e) => {
                  e.preventDefault();
                  onClickEdit(cellProps.row.original);
                }}
              />
              <UncontrolledTooltip
                placement='top'
                target={`item-price-green-product-tooltip-${cellProps.row.original.SCT_ID}`}
              >
                {t('Edit')}
              </UncontrolledTooltip>
            </>

            // <UncontrolledButtonDropdown>
            //   <DropdownToggle
            //     className='icon-btn hide-arrow'
            //     color='transparent'
            //     size='sm'
            //     caret
            //     style={{ padding: 0 }}
            //   >
            //     <MoreVertical size={15} />
            //   </DropdownToggle>
            //   <DropdownMenu container='body'>
            //     <DropdownItem
            //       href='/'
            //       onClick={(e) => {
            //         e.preventDefault();
            //         cellProps.row.original.INUSE == '1'
            //           ? onClickEdit(cellProps.row.original)
            //           : ToastMessageError({
            //               message: 'Cannot edit because already deleted !',
            //             });
            //       }}
            //     >
            //       <Edit
            //         className='me-50'
            //         size={15}
            //       />{' '}
            //       <span className='align-middle'>{t('Edit')}</span>
            //     </DropdownItem>
            //     <DropdownItem
            //       href='/'
            //       onClick={(e) => {
            //         e.preventDefault();
            //         cellProps.row.original.INUSE == '1'
            //           ? onClickDelete(cellProps.row.original)
            //           : ToastMessageError({
            //               message: 'Cannot delete because already deleted !',
            //             });
            //       }}
            //     >
            //       <Trash
            //         className='me-50'
            //         size={15}
            //         color='#F46A6A'
            //       />{' '}
            //       <span className='align-middle'>{t('Delete')}</span>
            //     </DropdownItem>
            //   </DropdownMenu>
            // </UncontrolledButtonDropdown>
          );
        }
      },
      {
        Header: t('Employee Id'),
        accessor: 'EMPLOYEE_ID',
        filterable: true,
        Cell: (cellProps) => {
          return <NormalColumn {...cellProps} />;
        }
      },
      {
        Header: t('Employee Name'),
        accessor: 'EMPLOYEE_FULL_NAME',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return <NormalColumn {...cellProps} />;
        }
      },
      {
        Header: t('Section'),
        accessor: 'EMPLOYEE_SECTION',
        filterable: true,
        Cell: (cellProps) => {
          return <NormalColumn {...cellProps} />;
        }
      },
      {
        Header: t('Start Work'),
        accessor: 'EMPLOYEE_START_WORK',
        filterable: true,
        Cell: (cellProps) => {
          return <NormalColumn {...cellProps} />;
        }
      },
      {
        Header: t('Leave Type'),
        accessor: 'LEAVE_TYPE_CODE',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return cellProps.value;
        }
      },
      {
        Header: t('Leave Remaining'),
        accessor: 'LEAVE_REMAIN_DAY',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return <NormalColumn {...cellProps} />;
        }
      }
    ],
    [t]
  );

  // redux
  const [{ queryPageIndex, queryPageSize, totalCount, querySortBy }, dispatch] = useReducer(reducer, initialState);

  // react-query
  //#region react-query => search-data
  const onSuccessSearchData = (data) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);

    if (data.data && data.data.Status == true) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data?.data?.TotalCountOnDb
      });
    } else {
      const message = {
        title: 'Search Remain AL Leave',
        message: data.data.Message
      };

      ToastMessageError(message);
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: 0
      });
    }
  };

  const onErrorSearchData = (error) => {
    setIsFetchData(false);
    setIsSearchDataForSearchForm(false);

    const message = {
      title: 'Search Remain AL Leave',
      message: error.message
    };
    ToastMessageError(message);
    dispatch({
      type: TOTAL_COUNT_CHANGED,
      payload: 0
    });
  };

  const { isLoading, error, data, isSuccess, isFetching } = useSearchRemainLeave(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(queryPageIndex, queryPageSize, querySortBy, getValues()),
    isFetchData
  );
  //#endregion react-query => search-data

  // table
  const table = useTable(
    {
      columns,
      data: isSuccess && data.data.Status == true ? data.data.ResultOnDb : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = table;

  // useEffect
  useEffect(() => {
    refTableData.current = table;
  }, []);

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });

    setIsFetchData(true);
  }, [pageIndex, pageSize, gotoPage, sortBy]);

  useEffect(() => {
    gotoPage(0);
  }, [sortBy, pageSize]);

  if (error) {
    return <p>{error.message}</p>;
  }

  if (isLoading) {
    return (
      <p className='card-text placeholder-glow'>
        <span className='placeholder col-7'></span>
        <span className='placeholder col-4'></span>
        <span className='placeholder col-4'></span>
        <span className='placeholder col-6'></span>
        <span className='placeholder col-8'></span>
      </p>
    );
  }

  const Loader = () => {
    return (
      <Fragment>
        <Spinner />
        <CardText>Loading data...</CardText>
      </Fragment>
    );
  };

  const handleExportRemainLeave = async () => {
    let today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
    let dateTime = date + time;

    const file = {
      thumb: null,
      name: 'REMAIN_LEAVE_' + dateTime,
      file: process.env.REACT_APP_API_END_POINT + 'leave/exportRemainLeave',
      filename: 'REMAIN_LEAVE_' + dateTime + '.xlsx'
      // params: getUrlParamExportFile(listResult)
    };

    download(file);
  };

  const handleExportALExceed = async () => {
    let today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
    let dateTime = date + time;

    const file = {
      thumb: null,
      name: 'AL_EXCEED_' + dateTime,
      file: process.env.REACT_APP_API_END_POINT + 'leave/exportALExceed',
      filename: 'AL_EXCEED_' + dateTime + '.xlsx'
    };

    download(file);
  };

  return (
    <>
      {isShowEditModel && (
        <RemainLeaveEditModal
          refTableData={refTableData}
          refRowSelected={refRowSelected}
          clearFilterFormSearch={clearFilterFormSearch}
          isShowEditModel={isShowEditModel}
          setIsShowEditModel={setIsShowEditModel}
          setIsFetchData={setIsFetchData}
        />
      )}

      <Card>
        <CardHeader className='border-bottom'>
          <CardTitle tag='h4'>{t('Search result')}</CardTitle>
        </CardHeader>

        <CardBody style={{ paddingBottom: '10px' }}>
          <Row className='mt-1 mb-0'>
            <Col sm='6'>
              {page.length > 0 && (
                <div className='d-flex align-items-center'>
                  <Label for='sort-select'>{t('Show')}</Label>
                  <Input
                    className='dataTable-select'
                    // style={{width:'unset'}}
                    type='select'
                    id='sort-select'
                    value={pageSize}
                    onChange={onChangeInSelect}
                    style={{ width: '5rem' }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                  <Label for='sort-select'>{t('entries')}</Label>
                </div>
              )}
            </Col>

            <Col
              className='d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1'
              sm='6'
              style={{ paddingRight: '3px' }}
            >
              <Button onClick={handleExportRemainLeave} color='primary' className='round'>
                <img
                  width='25'
                  height='25'
                  src='https://img.icons8.com/color/48/microsoft-excel-2019--v1.png'
                  alt='microsoft-excel-2019--v1'
                  className='me-1'
                />
                Export Remain Leave
              </Button>
              <div className='card-border-left'>
                <Button onClick={handleExportALExceed} color='success' className='round'>
                  <img
                    width='25'
                    height='25'
                    src='https://img.icons8.com/color/48/microsoft-excel-2019--v1.png'
                    alt='microsoft-excel-2019--v1'
                    className='me-1'
                  />
                  Export AL Exceed
                </Button>
              </div>
            </Col>

            {/* <Col
              className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1"
              sm="6"
              style={{ paddingRight: "3px" }}
            >
              <Button onClick={onClickAdd} color="success" className="round">
                <Plus size={14} /> Add New
              </Button>
            </Col> */}
          </Row>
        </CardBody>
        {page.length > 0 ? (
          <UILoader blocking={isFetching || isLoading} loader={<Loader />}>
            <div className='table-responsive react-table  mb-50'>
              {isSuccess ? (
                <Table bordered hover responsive {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th scope='col' className='text-nowrap' key={column.id}>
                            <div {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {generateSortingIndicator(column)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <Fragment key={row.getRowProps().key}>
                          <tr>
                            {row.cells.map((cell) => {
                              return (
                                <td scope='col' className='text-nowrap' key={cell.id} {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}
            </div>
            <Row className='justify-content-md-end justify-content-center align-items-center my-1 px-1'>
              <Col className='col-md-auto d-none d-md-block'>
                <strong>
                  {t('Showing')} {pageIndex * pageSize + 1} {t('to')}{' '}
                  {pageIndex * pageSize + (data?.data?.ResultOnDb?.length || 0)} {t('of')} {data?.data.TotalCountOnDb}{' '}
                  {t('entries')}
                </strong>
              </Col>
              <Col className='col-md-auto'>
                <div className='d-flex gap-1'>
                  <Button color='primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='small-btn'>
                    {'<<'}
                  </Button>
                  <Button color='primary' onClick={previousPage} disabled={!canPreviousPage} className='small-btn'>
                    {'<'}
                  </Button>
                </div>
              </Col>
              <Col className='col-md-auto d-none d-md-block'>
                Page{' '}
                <strong>
                  {pageIndex + 1} {t('of')} {pageOptions.length}
                </strong>
              </Col>
              <Col className='col-md-auto'>
                <Input
                  type='number'
                  min={1}
                  style={{ width: 70 }}
                  max={pageOptions.length}
                  placeholder={pageIndex + 1}
                  onChange={onChangeInInput}
                  className='small-input-page'
                />
              </Col>

              <Col className='col-md-auto'>
                <div className='d-flex gap-1'>
                  <Button color='primary' onClick={nextPage} disabled={!canNextPage} className='small-btn'>
                    {'>'}
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    className='small-btn'
                  >
                    {'>>'}
                  </Button>
                </div>
              </Col>
              <div style={{ marginTop: '1rem', textAlign: 'center' }} className='small-block'>
                <Col className='col-md-auto d-md-block'>
                  <strong>
                    {t('Showing')} {pageIndex * pageSize + 1} {t('to')}{' '}
                    {pageIndex * pageSize + (data?.data?.ResultOnDb[1]?.length || 0)} {t('of')}{' '}
                    {data?.data.ResultOnDb[0]?.length} {t('entries')}
                  </strong>
                </Col>
              </div>
            </Row>
          </UILoader>
        ) : (
          <NoResultsFound />
        )}
      </Card>
      {downloaderComponentUI}
    </>
  );
};

export default RemainLeaveTableData;
